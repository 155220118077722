import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import tick from '../../../assets/img/green_check.svg'
import cross from '../../../assets/img/remove.svg'

const SELECTED_BG_COLOR = '#FFC02C';
const SELECTED_COLOR = 'white';

const PASSED_BG_COLOR = '#018137';

let getColors = (isSelected, isPassed) => {
    if (isSelected) {
        return {
            backgroundColor: SELECTED_BG_COLOR,
            color: SELECTED_COLOR
        };
    }
    if (isPassed == true) {
        return {
            backgroundColor: PASSED_BG_COLOR,
            color: SELECTED_COLOR
        };
    }
    return {
        backgroundColor: 'transparent',
        color: 'black'
    }
}

export default function TopSquaresTool(props) {
    const {
        canSelect = true,
        cards = [],
        onSelect = id => {

        },
        selectedId,
        passedIds = [],
        goodMarkIds = [],
        canAddCard = false,
        onCardAdd = () => {

        },
        userAnswers = []
    } = props;

    console.log('TopSquaresTool: render: cards = ', cards);
    console.log('TopSquaresTool: render: userAnswers = ', userAnswers);
    console.log('TopSquaresTool: render: goodMarkIds = ', goodMarkIds);

    return (
        <Wrapper>

            {cards.map((a, i) => {
                let isSelected = (a.id == selectedId);
                let isGoodMark = (goodMarkIds.indexOf(a.id) > -1);
                let isPassed = (passedIds.indexOf(a.id) > -1);
                let ans = userAnswers.filter(xx => (xx.cardId == a.id))[0];
                let tComment = ans?.teacherComment;
                let hasComment = !(tComment == undefined || tComment == '');
                return (
                    <Square key={a.id}
                            canSelect={canSelect}
                            {...getColors(isSelected, isPassed)}
                            onClick={() => {
                                onSelect(a.id);
                            }}>
                        {ans == undefined ? null :
                            <DotsPlaceholder isGood={isGoodMark} >
                                {(ans?.assessmentType != 'binary' || ans?.teacherPassed == undefined) ? null :
                                    <>
                                        <SmallTick src={ans.teacherPassed ? tick : cross}/>
                                        {hasComment == false ? null :
                                            <span style={{marginRight: 2}}>{' | '}</span>
                                        }
                                    </>
                                }
                                {(ans?.assessmentType != 'rating' || (ans?.teacherMark == undefined && ans?.autoMark == undefined)) ? null :
                                    <span>
                                        {ans?.teacherMark || ans?.autoMark}
                                        {hasComment == false ? null :
                                            <span style={{marginRight: 2}}>{' | '}</span>
                                        }
                                    </span>
                                }

                                {(tComment == undefined || tComment == '') ? null :
                                    <span>
                                        {' c'}
                                    </span>
                                }
                            </DotsPlaceholder>
                        }
                        {+i + 1}
                    </Square>
                )
            })}
            {canAddCard == false ? null :
                <Square
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                        onCardAdd();
                    }}>
                    {'+'}
                </Square>
            }

        </Wrapper>
    );
}

const SmallTick = styled.img`
  height: 10px;
`;

const DotsPlaceholder = styled.div`
  position: absolute;
  top: -12px;
  left: 0px;
  right: 0px;
  z-index: 100;
  background: ${props => (props.isGood != true ? 'rgba(0, 0, 0, 0.05)' : '#C8EED3')};
  height: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  padding: 5px;
  width: 100%;
  text-align: center;
`;

const Square = styled.div`
  font-size: 16px;
  text-align: center;
  box-sizing: border-box;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  margin: 3px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  border: 1px solid #FFC02C;
  cursor: ${props => (props.canSelect ? 'pointer' : 'default')};

  position: relative;

  :hover {
    opacity: 0.8;
  }
`;