import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const TasksAPI = {

    async getTask(id) { // full
        let arr = (await axios.get(`${API_ENDPOINT}/v3/tasks/${id}`)).data.result;
        return arr;
    },

    async getLessonTasks(id) { // full
        let arr = (await axios.get(`${API_ENDPOINT}/v3/lesson/${id}/tasks`)).data.result;
        return arr;
    },

    async getTeacherTasks(teacherId) { // short
        let arr = (await axios.get(`${API_ENDPOINT}/v3/teacher/${teacherId}/tasks`)).data.result;
        return arr;
    },

    async getStudentTasks(studentId) { // short
        let arr = (await axios.get(`${API_ENDPOINT}/v3/student/${studentId}/tasks`)).data.result;
        return arr;
    },

    async getStudentTasksWithStats(studentId) { // short
        let arr = (await axios.get(`${API_ENDPOINT}/v3/student/${studentId}/tasks-stats`)).data.result;
        return arr;
    },


    async getClassTasks(classId) {  // short
        let arr = (await axios.get(`${API_ENDPOINT}/v3/class/${classId}/tasks`)).data.result;
        return arr;
    },


    async getLesson(id) {
        let arr = (await axios.get(`${API_ENDPOINT}/v3/lessons/${id}`)).data.result;
        return arr;
    },

    async createTask(lessonId, data) {
        if (lessonId == undefined || data == undefined) {
            throw 'lessonId or data is not defined';
        }
        let pld = (await axios.post(`${API_ENDPOINT}/v3/lesson/${lessonId}/tasks`, {
            ...data
        })).data.result;
        return pld;
    },

    async updateTask(data) {
        if (data?.id == undefined) {
            throw 'id is not provided';
        }
        let pld = (await axios.put(`${API_ENDPOINT}/v3/tasks/${data.id}`, {
            ...data
        })).data.result;
        return pld;
    },

    async copyDataToTaskFromSlide(taskId, slideId) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/tasks/${taskId}/copy-from-slide/${slideId}`)).data.result;
        return pld;
    },


    async deleteTask(id) {
        await axios.delete(`${API_ENDPOINT}/v3/tasks/${id}`);
    },


}

export default TasksAPI;
