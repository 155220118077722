import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import LottieLoader from "../loaders/LottieLoader";

import loader_json from '../../assets/lottie/under_construction.json'
import TreadmillsPanel from "../treadmills_exercises/panels/TreadmillsPanel";

import {translate} from "../../utils/Translate";
import FoldersTreePanel from "../folders/panels/FoldersTreePanel";

export default function TeacherTreadmillsApp(props) {
    const [tab, setTab] = useState('my');

    return (
        <TeacherTemplate active={'treadmills'}>

            <TopPlaceholder>
                <TopLeft>
                    {
                        [
                            {
                                label: translate('my_treadmills'),
                                value: 'my'
                            },
                            {
                                label: translate('all_treadmills'),
                                value: 'public'
                            },
                            {
                                label: translate('shared'),
                                value: 'shared'
                            },
                            {
                                label: 'Папки',
                                value: 'folders'
                            }
                        ].map((x, i) => {
                            return (
                                <TopRightTabItem selected={(x.value == tab)} key={i} onClick={() => {
                                    setTab(x.value);
                                }}>
                                    {x.label}
                                </TopRightTabItem>
                            )
                        })
                    }
                </TopLeft>
                <TopRight>

                </TopRight>
            </TopPlaceholder>

            <Wrapper>

                {tab != 'folders' ?
                    <TreadmillsPanel
                        mode={tab}
                    />
                    :
                    <div>
                        <FoldersTreePanel
                            contentType={'treadmills'}
                        />
                    </div>
                }



                {/*<ConstructionPlaceholder>*/}
                {/*    <LottieLoader*/}
                {/*        json={loader_json}*/}
                {/*    />*/}
                {/*    <div style={{marginTop: -100}}>*/}
                {/*        Инструкции появятся в ближайшее время...*/}
                {/*    </div>*/}
                {/*</ConstructionPlaceholder>*/}

            </Wrapper>


        </TeacherTemplate>
    );
}

const TopRightTabItem = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${props => (props.selected == true ? '#085BFF' : 'black')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  border-bottom: 6px solid ${props => (props.selected == true ? '#FFA71B' : 'white')};
  border-top: 6px solid white;
  box-sizing: border-box;
  height: 70px;

  @media (max-width: 1200px) {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  :last-of-type {

  }

  :hover {
    color: #085BFF;
  }
`;


const TopPlaceholder = styled.div`
  background: white;
  height: 70px;
  //margin-bottom: 30px;
  box-sizing: border-box;
  padding-left: ${props => (props.hasLogo ? 0 : 15)}px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

const TopRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;

  @media (max-width: 720px) {
    height: 95vh;
    overflow-y: auto;
  }
`;

const ConstructionPlaceholder = styled.div`
  height: 80vh;
  text-align: center;
`;