import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import {DEFAULT_TEXT_SLIDE_BACKGROUND} from "../config";

let getH = () => {
    try {
        return document.getElementsByClassName('treadmill_video_slide_placeholder')[0].clientHeight;
    } catch (exc) {

    }
}

export default function VideoSlide(props) {
    const {
        id,
        imageSrc,
        videoTime = 0,
        videoSrc,
        videoVolume = 10,
        audioSrc,
        audioVolume = 10,
        currentTime,
        isPreview = false,
        isPaused = false,
        overlayBackground = `rgba(0, 0, 0, 0.4)`,
        backgroundColor = '#000',
        hasBackgroundColor = false,
        textBackgroundColor = '#000',
        hasCountDown = false,
        timeBeforeSlideEnd = 0,

        text = '',

        textColor = 'white',
        textSize = 12, // in percents

        textPosition = 'center' // top, center, bottom
    } = props;

    let video = document.getElementsByClassName('treadmill_video_slide_video')[0];
    let audio = document.getElementsByClassName('treadmill_video_slide_audio')[0];
    let hasAudio = audioSrc !== undefined && audioSrc !== '';

    const [height, setHeight] = useState();
    const [timesPlayed, setTimesPlayed] = useState(1);

    useEffect(() => {
        if (height == undefined) {
            setHeight(getH());
        }
    }, []);

    let fontSize = height * textSize / 100.0;
    let lineHeight = 1.2 * fontSize;

    useEffect(() => {
        if (video) {
            if (props?.videoReplay?.shouldReplay === true) {
                let videoDur = video?.duration;
                let times = Math.floor(videoTime / videoDur);
                if (times >= props?.videoReplay?.times && props?.videoReplay?.noLimit === false) {
                    video.currentTime = 0;
                    video.pause();
                } else {
                    video.currentTime = videoTime - times * videoDur;
                    if (isPaused === false) {
                        video.play();
                    }
                }
            } else {
                video.currentTime = videoTime > 0 ? videoTime : 0;
                if (videoTime > video.duration) {
                    video.pause();
                }
            }
        }
        if (audio) {
            if (props?.audioReplay?.shouldReplay === true) {
                let audioDur = audio?.duration;
                let times = Math.floor(videoTime / audioDur);
                if (times >= props?.audioReplay?.times && props?.audioReplay?.noLimit === false) {
                    audio.currentTime = 0;
                    audio.pause();
                } else {
                    audio.currentTime = videoTime - times * audioDur;
                    if (isPaused === false) {
                        audio.play();
                    }
                }
            } else {
                audio.currentTime = videoTime > 0 ? videoTime : 0;
                if (videoTime > audio.duration) {
                    audio.pause();
                }
            }
        }
    }, [videoTime, id]);

    useEffect(() => {
        if (audio) {
            audio.volume = audioVolume / 10;
        }
    }, [audio, audioVolume]);

    useEffect(() => {
        if (video) {
            if (videoVolume !== undefined) {
                video.volume = videoVolume / 10;
            }
        }
    }, [video, videoVolume])

    useEffect(() => {
        isPaused ? video?.pause() : video?.play();
        isPaused ? audio?.pause() : audio?.play();
    }, [isPaused, id])

    useEffect(() => {
        if (video !== undefined && props?.videoReplay?.shouldReplay === true) {
            video.onended = () => {
                if (timesPlayed < props?.videoReplay?.times || props?.videoReplay?.noLimit === true) {
                    video.play();
                    setTimesPlayed(timesPlayed + 1);
                }
            }
        }
    }, [currentTime, video])

    useEffect(() => {
        if (audio !== undefined && props?.audioReplay?.shouldReplay === true) {
            audio.onended = () => {
                if (timesPlayed < props?.audioReplay?.times || props?.audioReplay?.noLimit === true) {
                    audio.play();
                    setTimesPlayed(timesPlayed + 1);
                }
            }
        }
    }, [currentTime, audio])

    return (
        <Wrapper className='treadmill_video_slide_placeholder'>
            {imageSrc == undefined || imageSrc == '' ? null :
                <Image src={imageSrc} />
            }
            <VideoPlaceholder>
                <Video className={'treadmill_video_slide_video'} controls={isPreview} autoPlay={!isPaused && !isPreview}>
                    <source src={videoSrc}/>
                </Video>
            </VideoPlaceholder>
            <TextLayerPlaceholder textPosition={textPosition}>
                <Text style={{
                    fontSize: `${fontSize}px`,
                    lineHeight: `${lineHeight}px`,
                    color: textColor,
                    backgroundColor: hasBackgroundColor ? textBackgroundColor : 'transparent'
                }}>
                    <div dangerouslySetInnerHTML={{__html: `${text}`}}></div>
                </Text>
                
            </TextLayerPlaceholder>
            {hasAudio === false || isPreview ? null :
                <audio key={id} className={'treadmill_video_slide_audio'} autoPlay={!isPaused}>
                    <source src={`${audioSrc + `#t=${videoTime}`}`}/>
                </audio>
            }
        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${props => (props.background == undefined ? DEFAULT_TEXT_SLIDE_BACKGROUND : props.background)};
`;

const VideoPlaceholder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
`;

const Video = styled.video`
  height: 100%;
  //width: 100%;
  max-width: 100%;
  background: black;
  max-height: 100%;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  object-fit: contain;
  z-index: 0;
`;

const TextLayerPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  z-index: 5;
  justify-content: ${props => ({'top': 'flex-start', 'bottom': 'flex-end', 'center': 'center'}[props.textPosition])};
`;

const Text = styled.div`
  text-align: center;
  z-index: 10;
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
  min-height: 100%;
  z-index: 1;
`;