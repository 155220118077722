import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../helpers/CommonHelper";
// import ep_logo_wide_img from "./images/ep_new_year_logo.svg";
import ep_logo_wide_img from "./images/ep_logo_wide.svg";
import logo_eng_pro_uni from "./images/pro_uni_logo_3.svg";
// import speakrus_png from "./images/speakrus_logo.png";
import china_logo from "./images/china_logo.svg";
import speakrus_png from "./images/speak_rus_upd.svg";
import likool_logo from "./images/likool_logo.png";
import hebrew_logo from "./images/hebrewlang_logo.jpg";
import psy_logo from "./images/psy_logo.png";
// import kuzgtu_logo from "./images/kuzgtu_logo.png";
import kuzgtu_logo from "./images/vie_tomsk.png";
import speak_rus_fav from "./images/speak_rus_fav.png";

export function setBranding(setLogo = () => {

}) {
    let link = document.querySelector("link[rel~='icon']");
    window.document.title = 'Английский Пациент';
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    if (window.location.href.indexOf('prouni') > -1) {
        setLogo(logo_eng_pro_uni);
        window.document.title = 'EnglishProUni';
    }
    if (window.location.href.indexOf('ru-tsu') > -1) {
        setLogo(speakrus_png);
        window.document.title = 'SpeakRus';
        link.href = speak_rus_fav;
    }
    if (window.location.href.indexOf('psyguide') > -1) {
        setLogo(psy_logo);
        window.document.title = 'PsyGuide.Online';
    }

    if (window.location.href.indexOf('likool') > -1 || window.location.href.indexOf('neuroschool') > -1 ) {
        setLogo(likool_logo);
        window.document.title = 'Likool';
    }
    if (window.location.href.indexOf('hebrew') > -1) {
        setLogo(hebrew_logo);
        window.document.title = 'HebrewLang';
    }
    if (window.location.href.indexOf('cn-') > -1) {
        setLogo(china_logo);
        window.document.title = 'CN-TSU';
    }
    if (window.location.href.indexOf('vietnam') > -1) {
        setLogo(kuzgtu_logo);
        window.document.title = 'VIETNAM';
    }
}

export default function LogoDiv(props) {
    const [logo, setLogo] = useState(ep_logo_wide_img);
    const [updT, setUpdT] = useState(0);

    useEffect(() => {
        try {
            setBranding(setLogo);
        } catch (exc) {

        }
    }, []);
    return (
        <LogoPlaceholder key={`xx_${updT}`} onClick={() => {
            CommonHelper.linkTo('/');
        }}>
            <Logo src={logo}/>
        </LogoPlaceholder>
    );
}

const topHeight = 70;
const leftWidth = 300;

const Logo = styled.img`
  width: 100%;
  height: 100%;
`;

const LogoPlaceholder = styled.div`
  width: ${leftWidth}px;
  height: ${topHeight}px;
  cursor: pointer;
  box-sizing: border-box;

  :hover {
    opacity: 0.8;
  }

  @media (max-width: 720px) {
    display: none;
  }

`;
