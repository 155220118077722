import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';

import * as drockaExercisesActions from '../../../redux/actions/DrochkaExercisesActions'
import BrutalOverlayModal from "../../modals/BrutalOverlayModal";
import NiceModal from "../../modals/NiceModal";
import Tabs, {TabItem} from "../../ui/Tabs";
import DrochkaExercisePanel from "./DrochkaExercisePanel";
import DrochkaInfoForm from "../forms/DrochkaInfoForm";
import DrochkaItemsForm from "../forms/DrochkaItemsForm";
import {Code} from "react-content-loader";
import {GreenButton} from "../../ira/ui/Buttons";
import {Input} from "../../ira/ui/Inputs";
import {Set} from "immutable";
import {translate} from "../../../utils/Translate";
import tick_img from "../../../assets/img/green_check.svg";
import {hasIntersection} from "../../exercises/panels/AllExercisesPanel";
import CommonHelper from "../../../helpers/CommonHelper";
import share_img from "../../../assets/img/share_mini.svg";

export default function AllDrochkaExercisesPanel(props) {
    const {
        mode = 'my',
        onCreated = x => {

        },
        selectMode = false,
        onSelect = x => {

        },
        selectedSet = Set(),
        creatorId
    } = props;

    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState();
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [searchText, setSearchText] = useState('');

    const {
        currentUser,
        exercises = [],
        loading,
        isModerator
    } = useMappedState(useCallback(state => {
        let {currentUserId} = state.users;
        let currentUser = state.users.usersMap.get(currentUserId);
        let allExercises = state.drochkaExercises.drochkaExercisesMap.toArray();
        let exercises = (mode == 'my') ? allExercises.filter(x => (x.ownerId == currentUserId)) : allExercises;

        let aGroups = state.accessGroups.accessGroupsMap.toArray().filter(x => ((x?.emails || []).indexOf(currentUser.email) == -1 && x?.creatorId != currentUserId));
        let aGroupsIds = aGroups.map(xx => xx?.id).filter(x => (x != undefined));
        if (mode == 'shared') {
            exercises = exercises.filter(x => hasIntersection((x?.accessGroupsIds || []), aGroupsIds));
        }
        if (mode == 'public') {
            exercises = exercises.filter(x => (x?.access == 'public'));
            // exercises = exercises;
        }

        exercises = exercises.sort((a, b) => (+b.timestamp - +a.timestamp));
        if (creatorId != undefined){
            exercises = exercises.filter(x => (x?.ownerId == creatorId));
        }
        return {
            currentUser: currentUser,
            isModerator: currentUser != undefined && currentUser.isModerator,
            exercises: exercises,
            loading: state.drochkaExercises.loading
        }
    }, [selectedId]));

    useEffect(() => {
        dispatch(drockaExercisesActions.getAllDrochkaExercises());
    }, []);

    let selectedExercise = (selectedId == undefined) ? undefined : exercises.filter(x => (x.id == selectedId))[0];
    let xExercises = exercises;
    if (searchText.length > 2) {
        xExercises = xExercises.filter(x => {
            let s = `${x.name || ''} ${x.description || ''} ${x.transcript || ''}`.toLowerCase();
            return (s.indexOf(searchText.toLowerCase()) > -1);
        });
    }

    return (
        <Wrapper>

            {!(exercises.length == 0 && loading == true) ? null :
                <Code/>
            }

            <TopPlacehodler>
                {mode != 'my' ? null :
                    <div>
                        <GreenButton onClick={() => {
                            setAddModalVisible(true);
                        }}>
                            Создать карточку
                        </GreenButton>
                    </div>
                }


                <SearchPlaceholder>
                    <Input
                        placeholder={'Поиск'}
                        onChange={evt => {
                            let s = evt.target.value;
                            setSearchText(s);
                            // setPage(0);
                        }}
                    />
                </SearchPlaceholder>

            </TopPlacehodler>

            <ItemsPlaceholder>
                {xExercises.map((a, i) => {
                    let {grammarTags = [], subjectTags = []} = a;
                    let isSelected = selectedSet.has(a.id);
                    return (
                        <ExItem key={`ex_${a.id}_${i}`} onClick={() => {

                        }}>
                            <AvatarImage src={a.avatar} onClick={() => {
                                setSelectedId(a.id);
                            }}/>
                            <Name onClick={() => {
                                setSelectedId(a.id);
                            }}>
                                {a.name}
                            </Name>
                            <TagsDiv>
                                {grammarTags.map(xx => (
                                    <TagSpan
                                        key={xx}>{xx}</TagSpan>))}
                                {subjectTags.map(xx => (
                                    <TagSpan
                                        key={xx}>{xx}</TagSpan>))}
                            </TagsDiv>
                            <Description onClick={() => {
                                setSelectedId(a.id);
                            }}>
                                {a.description}
                            </Description>
                            {selectMode == false ? null :
                                <div style={{
                                    marginTop: 10,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    {isSelected == false ? null :
                                        <img src={tick_img} style={{height: 18, marginRight: 5}}/>
                                    }
                                    <SelectSpan onClick={() => {
                                        onSelect(a);
                                    }}>
                                        {translate('select')}
                                    </SelectSpan>
                                </div>
                            }
                        </ExItem>
                    )
                })}
            </ItemsPlaceholder>

            {selectedExercise == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedId(undefined);
                }}>

                    <InnerPlaceholder>
                        <Tabs
                            tabs={[
                                (mode != 'my' && window.location.href.indexOf('petr') == -1 && isModerator == false) ? undefined :
                                    {
                                        label: 'Информация',
                                        content: (
                                            <TabItem>
                                                <DrochkaInfoForm
                                                    {...selectedExercise}
                                                    loading={loading}
                                                    onSave={async d => {
                                                        await dispatch(drockaExercisesActions.updateExercise({
                                                            ...d,
                                                            id: selectedExercise?.id
                                                        }))
                                                    }}
                                                />
                                            </TabItem>
                                        )
                                    },
                                (mode != 'my' && window.location.href.indexOf('petr') == -1 && isModerator == false) ? undefined :
                                    {
                                        label: 'Карточки',
                                        content: (
                                            <TabItem>
                                                <DrochkaItemsForm
                                                    loading={loading}
                                                    {...selectedExercise}
                                                    onSave={async arr => {
                                                        await dispatch(drockaExercisesActions.updateExercise({
                                                            items: arr,
                                                            id: selectedExercise?.id
                                                        }))
                                                    }}
                                                />
                                            </TabItem>
                                        )
                                    },
                                {
                                    label: 'Превью',
                                    content: (
                                        <TabItem>
                                            <DrochkaExercisePanel
                                                id={selectedExercise?.id}
                                            />

                                            <ShareExecisePlaceholder>
                                                <ShareLinkSpan onClick={() => {
                                                    CommonHelper.linkTo(`/share/cards/${selectedExercise?.id}`, true);
                                                }}>
                                                    <ShareImg src={share_img}/>
                                                    {translate('share_cards')}
                                                </ShareLinkSpan>
                                            </ShareExecisePlaceholder>

                                        </TabItem>
                                    )
                                }
                            ].filter(x => (x != undefined))}
                        />
                    </InnerPlaceholder>

                </NiceModal>
            }

            {addModalVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddModalVisible(false);
                }}>

                    <InnerPlaceholder>
                        <DrochkaInfoForm
                            loading={loading}
                            onSave={async d => {
                                let pld = await dispatch(drockaExercisesActions.createExercise({
                                    ...d
                                }));
                                setAddModalVisible(false);
                                setSelectedId(pld?.exercise?.id);
                                onCreated(pld?.exercise);
                            }}
                        />
                    </InnerPlaceholder>

                </NiceModal>
            }

        </Wrapper>
    );
}


const ShareLinkSpan = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.75;

  :hover {
    opacity: 1;
  }
`;

const ShareExecisePlaceholder = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
  margin-top: 10px;
`;

const ShareImg = styled.img`
  margin-right: 5px;
  width: 16px;
  height: 16px;
`;

const SearchPlaceholder = styled.div`
  width: 240px;
`;

const TagsDiv = styled.div`
  padding: 4px;
  padding-left: 0px;
  padding-right: 0px;
`;

const Wrapper = styled.div`

`;

const TagSpan = styled.span`
  margin-right: 5px;
  border: 1px solid whitesmoke;
  border-radius: 4px;
  line-height: 16px;
  font-size: 12px;
  opacity: 0.5;
  font-style: italic;
  padding-left: 8px;
  padding-right: 8px;
  background: #d3d3d363;
`;

const SelectSpan = styled.span`
  cursor: pointer;
  font-size: 12px;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const TopPlacehodler = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const InnerPlaceholder = styled.div`
  width: 920px;
  box-sizing: border-box;
  padding: 5px;
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  
  @media (max-width: 720px){
    width: 75vw;
    ::-webkit-scrollbar {
      width: 0;
    }
  }
`;

const Description = styled.div`
  font-size: 12px;
  opacity: 0.7;
`;

const Name = styled.div`
  font-weight: bold;
`;

const AvatarImage = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: whitesmoke;
  height: 240px;
  width: 100%;
  margin-bottom: 5px;
  @media (max-width: 1100px) {
    height: 180px;
  }
`;

const ExItem = styled.div`
  box-sizing: border-box;
  margin: 15px;
  background: white;
  padding: 15px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.75;

  background: ${props => (props.selected == true ? 'blue' : 'white')};

  :hover {
    opacity: 1;
  }
`;

const Row = styled.div`
  margin-bottom: 15px;
`;

const ItemsPlaceholder = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px){
    grid-template-columns: 1fr;
  }
`;