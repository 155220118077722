import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Spin from 'arui-feather/spin'
import {SAMPLE_ITEMS} from "../config";
import TreadmillPlayerTool from "./TreadmillPlayerTool";
import {Map} from 'immutable'
import {isMobile} from "polotno/utils/screen";
import {BlueButton, RedButton} from '../../../ira/ui/Buttons'
import speechRecognitionAPI from "../../../../api/SpeechRecognitionAPI";
import axios from "axios";
import {API_ENDPOINT} from "../../../../constants/config";
import RecorderAPI from "../../../../api/RecorderAPI";
import {useMappedState} from "redux-react-hook";
import NiceConfirm from "../../../modals/NiceConfirm";
import TeacherSettingsAPI from "../../../../api/TeacherSettingsAPI";
import TreadmillRecordComponent from "../../../record/TreadmillRecordComponent";
import SliderTreadmillTool from "./SliderTreadmillTool";
import help_svg from "../../../../assets/img/help.svg";
import mic_svg from "../../../../assets/img/mic.svg"
import pen_svg from "../../../../assets/img/pen_svg.svg"
import NiceModal from "../../../modals/NiceModal";
import toast from "react-hot-toast";
import DiffHelper from "../../../../helpers/DiffHelper";
import ls from "local-storage";

async function getImageBlob(url) {
    const res = await fetch(url);
    const blob = await res.blob();
    return blob;
}

async function cacheImages(urls, onProgress = x => {

}) {
    if (urls == undefined || urls.length == 0) {
        return {};
    }
    let map = Map();
    for (let i in urls) {
        let u = urls[i];
        try {
            let b = await getImageBlob(u);
            map = map.set(`${u}`, URL.createObjectURL(b))
            // map[u] = URL.createObjectURL(b);
            console.log(`--> ${u} OK`);
        } catch (exc) {
            // map[u] = u;
            map = map.set(`${u}`, u);
        }
    }
    return map;
}

export default function SmartTreadmillPlayerTool(props) {
    const {
        userId,
        settings,
        currentUser,
        items = [],
        canAnswer = false,
        userAnswers = undefined,
        isPreview = false,
        shareMode = false,
        showTime = false,
        showRes = false,
        name,
        onAnswer = d => {

        },
        onFinish = value => {

        },
        onDeleteAnswers = () => {

        },
        onStart = () => {

        },
        allowClosing = () => {

        },
        setCurrentSlide = d => {

        }

    } = props;

    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading])


    const urls = items.map(x => x.imageSrc).filter(x => (x != undefined))
        .concat(items.map(x => x.videoSrc).filter(x => (x != undefined)))
        .concat(items.map(x => x.audioSrc).filter(x => (x != undefined)));

    const [isPaused, setIsPaused] = useState(false);
    const [timerValue, setTimerValue] = useState({time: 0});

    const [caching, setCaching] = useState(true);
    const [cachingMap, setCachingMap] = useState(Map());
    const [isStarted, setIsStarted] = useState(false);
    const [currentTime, setCurrentTime] = useState({time: 0});
    const [mineAnswers, setMineAnswers] = useState([]);
    const [loading, setLoading] = useState(props.loading);
    const [showResult, setShowResult] = useState(showRes);
    const [deleteAnswersModalOpen, setDeleteAnswersModalOpen] = useState(false);
    const [instructionModalVisible, setInstructionModalVisible] = useState(false);
    const [answer, setAnswer] = useState([]);
    const [canStart, setCanStart] = useState(false);

    useEffect(() => {
        if (userAnswers !== []) {
            setShowResult(true);
        } else {
            setShowResult(showRes)
        }
    }, [showRes])

    useEffect(() => {
        let previewTime = ls(`treadmill_preview_${props.id}`);
        if (previewTime > 0) {
            setCurrentTime({time: previewTime})
            setTimerValue({time: previewTime})
        }
        ls.remove(`treadmill_preview_${props.id}`)
    }, [])

    let treadmillTime = items?.length === 0 ? 0 : items.at(-1).to;

    const minesAmount = (items.filter(x => x?.hasMine === true)).length;
    const textAnswersAmount = (items.filter(x => x?.inputType === 'text')).length;
    const voiceAnswersAmount = (items.filter(x => x?.inputType === 'voice')).length;
    let isTeacher = currentUser?.userRole === 'teacher';

    navigator.permissions.query({ name: 'microphone' }).then(function(permissionStatus) {
        if (permissionStatus.state === 'granted') {
            setCanStart(true);
        }
        permissionStatus.onchange = function(){
            if (this.state !== 'granted' && +minesAmount + +voiceAnswersAmount > 0) {
                setCanStart(false);
            } else {
                setCanStart(true);
            }
        }
    })

    const [checkingMicro, setCheckingMicro] = useState(isTeacher || shareMode || isPreview || minesAmount + voiceAnswersAmount === 0 ? false : true);

    async function uploadBlob(blob, id) {
        let url = await RecorderAPI.uploadUserAudio(blob, p => {
            if (window.isNaN(p)) {
                return;
            }
        });
        return {
            id: id,
            url: url.url
        }
    }

    async function checkMine(item) {
        let url = await RecorderAPI.uploadUserAudio(item.blob, p => {
            if (window.isNaN(p)) {
                return;
            }
        });
        let pld = await speechRecognitionAPI.recognize(url.url, item.language); //распознали в текст
        let recognizedText = '';
        for (let item of pld) {
            recognizedText = recognizedText.concat(item.text).concat(' ');
        }
        let mineAnswer;
        if (recognizedText.trim() === '') {
            mineAnswer = {
                id: item.id,
                url: url.url,
                recognizedText: recognizedText,
                correctAnswer: item.correctAnswer,
                resultScore: 0,
                hasPassed: false
            }
        } else {
            let pld = DiffHelper.getScore(item.correctAnswer, recognizedText);

            mineAnswer = {
                id: item.id,
                url: url.url,
                recognizedText: recognizedText,
                correctAnswer: item.correctAnswer,
                resultScore: pld.resultScore,
                hasPassed: pld.resultScore >= 60
            }
        }
        return mineAnswer;
    }

    function updateAnswer(data) {
        setAnswer(answer => answer.concat(data));
    }

    function restart() {
        if ((settings !== undefined && (settings?.noLimit || settings?.attemptsLeft > 0)) || isTeacher || shareMode) {
            setIsStarted(false);
            setCurrentTime({time: 0});
            setShowResult(true);
        }
    }

    useEffect(() => {
        if (isPreview === false) {
            if (currentTime.time === treadmillTime) {
                allowClosing();
                setLoading(true);
                if (shareMode || isTeacher) {
                    restart();
                    setLoading(false);
                }
            }
            if (currentTime.time >= treadmillTime && shareMode === false) {
                let hasMines = settings === undefined || settings?.recordMines === true;
                let totalCount = +textAnswersAmount + +voiceAnswersAmount + (hasMines ? +minesAmount : 0);
                if (answer.length === totalCount) {
                    onFinish(answer);
                    restart();
                    setShowResult(true);
                    setIsStarted(false);
                    setLoading(false);
                }
            }
        }
    }, [answer, currentTime])

    useEffect(() => {
        if (isPreview && currentTime.time === treadmillTime) {
            setIsPaused(true);
        }
    }, [currentTime])

    useEffect(() => {
        setCaching(true);
        cacheImages(urls).then(xMap => {
            setCachingMap(xMap);
            setCaching(false);
        });
    }, []);

    useEffect(() => {
        if (checkingMicro === false) {
            setCanStart(true);
        }
        setTimeout(() => {
            setCheckingMicro(false);
        }, 200)
    }, [])

    useEffect(() => {
        if (+minesAmount + +voiceAnswersAmount > 0 && isTeacher === false && shareMode === false) {
            if (isStarted === true && canStart === false) {
                setIsStarted(false);
                allowClosing();
                toast.error('Разрешите доступ к микрофону');
            }
        }
    }, [canStart])

    if (checkingMicro) {
        return (
            <TreadmillRecordComponent recordingTime={0.1} onError={() => {
                setCanStart(false);
            }}/>
        )
    }

    if (caching === true || loading === true) {
        return (
            <CachingPlaceholder>
                <CachingText>
                    <Spin visible={true}/>
                </CachingText>
            </CachingPlaceholder>
        )
    }

    const cachedItems = items.map(x => {
        return {
            ...x,
            audioSrc: (x.audioSrc == undefined) ? undefined : cachingMap.get(x.audioSrc),
            imageSrc: (x.imageSrc == undefined) ? undefined : cachingMap.get(x.imageSrc),
            videoSrc: (x.videoSrc == undefined) ? undefined : cachingMap.get(x.videoSrc)
        }
    })

    return (
        <Wrapper>
            {window.innerWidth < 680 ? /*isMobile() == true*/
                <div style={{
                    width: '100%',
                    height: '100%', display: 'flex',
                    justifyContent: 'center', alignItems: 'center',
                    fontWeight: 'bold', fontSize: '30px'
                }}>
                    <div>
                        Использование тредмилов возможно только с компьютера
                    </div>
                </div>
                :
                <>
                    {(isPreview === false && (isStarted === false || currentTime > treadmillTime)) ? null :
                        <TreadmillPlayerTool
                            currentUser={currentUser}
                            items={cachedItems}
                            isPreview={isPreview || (isTeacher && currentUser.id === userId)}
                            shareMode={shareMode}
                            isTeacher={isTeacher}
                            treadmillTime={treadmillTime}
                            isPaused={isPaused}
                            settings={settings}
                            timerValue={timerValue}
                            setCurrentTime={time => {
                                setCurrentTime(time);
                            }}
                            setCurrentSlide={item => setCurrentSlide(item)}
                            onMineAnswer={async (id, blob, correctAnswer, language) => {
                                let newMineAnswers = mineAnswers.concat({
                                    id: id,
                                    blob: blob,
                                    correctAnswer: correctAnswer
                                })
                                setMineAnswers(newMineAnswers);
                                let res = await checkMine({
                                    id: id,
                                    blob: blob,
                                    correctAnswer: correctAnswer,
                                    language: language === undefined ? 'en' : language
                                })
                                updateAnswer(res);
                            }}
                            onVoiceAnswer={async (id, blob) => {
                                let ans = await uploadBlob(blob, id);
                                updateAnswer(ans);
                            }}
                            onLoading={() => {
                                setLoading(true)
                            }}
                            onAnswer={ans => {
                                if (isTeacher === false) {
                                    updateAnswer(ans);
                                }
                            }}
                            canAnswer={canAnswer}
                        />
                    }

                    {cachedItems.length > 0 ? null :
                        <span>Тредмил пуст</span>
                    }

                    {((showTime === false || isStarted === false) && isPreview === false) || currentTime > treadmillTime || cachedItems.length === 0 ? null :
                        <TimerPlaceholder>
                            <SliderTreadmillTool
                                treadmillTime={treadmillTime}
                                currentTime={currentTime?.time}
                                canRewind={(settings !== undefined && settings.noLimit === true) || isPreview}
                                restart={() => {
                                    setCurrentTime({time: 0});
                                    setTimerValue({time: 0});
                                }}
                                setTime={time => {
                                    setTimerValue({time: time});
                                }}
                                setPause={val => {
                                    setIsPaused(val);
                                }}
                            />
                        </TimerPlaceholder>
                    }

                    {userAnswers === undefined || userAnswers?.items.length === 0 || (isStarted && currentTime < treadmillTime) || isPreview || shareMode ? null :
                        <ResultPlaceholder>
                            {isTeacher === false ?
                                <StudentInfoPlaceholder>
                                    <span style={{fontSize: '24px'}}><b>{name}</b></span>
                                    {cachedItems.length < 1 ? null :
                                        <div><b>Время:</b> {treadmillTime} сек</div>
                                    }
                                    {textAnswersAmount < 1 ? null :
                                        <div><b>Текстовые
                                            задания:</b> {userAnswers?.result?.mark} ({textAnswersAmount} {textAnswersAmount % 10 === 1 ? 'задание'
                                            : textAnswersAmount % 10 < 5 && textAnswersAmount % 10 > 1 ? 'задания' : 'заданий'})
                                        </div>
                                    }
                                </StudentInfoPlaceholder>
                                :
                                <TeacherInfoPlaceholder>
                                    <TeacherInfoInnerPlaceholder>
                                        {userAnswers?.items.length === 1 && userAnswers?.items[0]?.completed === true ?
                                            <div style={{alignSelf: 'center', fontSize: '18px'}}>Студент выполнил
                                                тредмил</div>
                                            :
                                            userAnswers?.items.map((a, i) => {
                                                if (a?.recognizedText !== undefined) {
                                                    return (
                                                        <Answer key={i}>
                                                            <div><b>{i + 1}. Запись:</b></div>
                                                            <audio controls={true} src={a.url}></audio>
                                                            <div><b>Распознано: </b><br/> {a.recognizedText}</div>
                                                            <div><b>Правильный ответ: </b><br/> {a.correctAnswer}</div>
                                                            <div><b>Совпадение:</b> {a.resultScore}%</div>
                                                            <div><b>Мина пройдена: </b>{a.hasPassed ? 'да' : 'нет'}
                                                            </div>
                                                        </Answer>
                                                    )
                                                } else if (a?.mark !== undefined) {
                                                    return (
                                                        <Answer key={i}>
                                                            <div><b>{i + 1}. Ответ:</b> {a.answer}</div>
                                                            <div><b>Правильный ответ:</b> {a.correctAnswer}</div>
                                                            <div><b>Совпадение:</b> {a.resultScore}</div>
                                                            <div><b>Оценка:</b> {a.mark}</div>
                                                        </Answer>

                                                    )
                                                } else {
                                                    return (
                                                        <Answer key={i}>
                                                            <div><b>{i + 1}. Запись:</b></div>
                                                            <audio controls={true} src={a.url}></audio>
                                                        </Answer>
                                                    )
                                                }
                                            })
                                        }
                                    </TeacherInfoInnerPlaceholder>
                                    <DeleteAnswers onClick={() => {
                                        setDeleteAnswersModalOpen(true);
                                    }}>
                                        удалить ответы студента
                                    </DeleteAnswers>
                                </TeacherInfoPlaceholder>
                            }
                        </ResultPlaceholder>
                    }

                    {settings !== undefined && settings?.attemptsLeft === 0 && !isTeacher && !isStarted && !shareMode ?
                        <div style={{marginTop: '10px', fontSize: '16px'}}>Попыток осталось: 0</div>
                        : null
                    }

                    {isStarted === true || loading || isPreview || settings?.attemptsLeft === 0 || (isTeacher && userAnswers?.items !== undefined) || cachedItems.length == 0 ? null :
                        <StartButtonPlaceholder>
                            <TreadmillLength>Длительность тредмила: {items[items.length - 1]?.to} сек</TreadmillLength>
                            <RedButton style={{marginBottom: '10px', width: 'fit-content', background: !canStart && !isTeacher && !shareMode ? 'lightgrey' : ''}}
                                       onClick={() => {
                                           if (canStart || isTeacher || shareMode) {
                                               setAnswer([])
                                               setIsStarted(true);
                                               setShowResult(false);
                                               setTimerValue({time: 0});
                                               setCurrentTime({time: 0});
                                               if (!isTeacher) {
                                                   onStart();
                                               }
                                           } else if (!canStart) {
                                               toast.error('Разрешите доступ к микрофону');
                                           }
                                       }}>
                                start
                            </RedButton>
                            {isTeacher || settings?.noLimit === true || shareMode ? null :
                                <div style={{fontSize: '16px'}}>
                                    Попыток осталось: {settings === undefined ? '1' : settings.attemptsLeft}
                                </div>
                            }
                            {isTeacher || settings === undefined || settings?.noLimit === false || shareMode ? null :
                                <div style={{fontSize: '16px'}}>
                                    Количество попыток неограниченно
                                </div>
                            }
                            <Instruction src={help_svg} onClick={() => {
                                setInstructionModalVisible(true);
                            }}/>
                        </StartButtonPlaceholder>
                    }

                    {isStarted === false || shareMode || isTeacher ? null :
                        <div style={{display: 'none'}}>
                            <TreadmillRecordComponent id={props.id} recordTime={treadmillTime} setUrl={blob => {}}/>
                        </div>
                    }

                    {deleteAnswersModalOpen == false ? null :
                        <NiceConfirm
                            heading={'Подтвердите удаление'}
                            subHeading={'Вы уверены? Ответы студента будут удалены.'}
                            onConfirm={async () => {
                                onDeleteAnswers();
                                setShowResult(false);
                                setDeleteAnswersModalOpen(false);
                            }} onCancel={() => {
                            setDeleteAnswersModalOpen(false);
                        }}>

                        </NiceConfirm>
                    }

                    {instructionModalVisible === false ? null :
                        <NiceModal onClose={() => setInstructionModalVisible(false)}>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '10px', fontSize: '18px', width: '350px'}}>
                                Как только на экране появится значок, вы должны:
                                <LinePlaceholder>
                                    <Icon src={pen_svg}/> - писать
                                </LinePlaceholder>
                                <LinePlaceholder>
                                    <Icon src={mic_svg}/> - говорить
                                </LinePlaceholder>
                            </div>
                        </NiceModal>
                    }
                </>
            }

        </Wrapper>
    );
}

const CachingPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CachingText = styled.div`
  text-align: center;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const TimerPlaceholder = styled.div`
  width: 100%;
  display: flex;
`;

const DeleteAnswers = styled.span`
  justify-self: center;
  color: red;
  cursor: pointer;
  opacity: 0.5;
  font-size: 16px;
  margin-top: 10px;

  :hover {
    opacity: 1;
  }
`;

const StudentInfoPlaceholder = styled.div`
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Answer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  font-size: 16px;
`;

const ResultPlaceholder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const TeacherInfoPlaceholder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 500px;
`;

const TeacherInfoInnerPlaceholder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
`;

const StartButtonPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Instruction = styled.img`
  margin-top: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0.3;
  
  :hover {
    opacity: 1;
  }
`;

const LinePlaceholder = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  height: 24px;
  width: 24px;
  margin: 5px;
`;

const TreadmillLength = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`;