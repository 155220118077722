import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from "redux-react-hook";

import * as actions from '../../../redux/actions/GroupsActions.js'
import * as usersActions from '../../../redux/actions/UsersActions.js'
import CommonHelper from "../../../helpers/CommonHelper";

import peopleImg from '../../../assets/img/people.svg'
import grLevelImg from '../../../assets/img/group_level_img.svg'
import FacesListTool from "../tools/FacesListTool";
import NiceModal from "../../modals/NiceModal";
import {Input} from "../../ira/ui/Inputs";

import Spin from 'arui-feather/spin'
import LottieLoader from "../../loaders/LottieLoader";
import ls from 'local-storage'

import group_loading_lottie from '../../../assets/lottie/group_loading_lottie.json'
import not_groups_lottie from '../../../assets/lottie/not_groups_lottie.json'
import {BlueButton} from "../../ira/ui/Buttons";
import {translate} from "../../../utils/Translate";


export default function GroupsPanel(props) {
    const {
        modulesIds = [],
        onModuleClick = mId => {

        },
        userId
    } = props;

    const {
        groups = [],
        loading,
        currentUser,
        userRole,
        uId,
        user
    } = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let uId = userId || currentUser?.id;
        let user = state.users.usersMap.get(uId);

        return {
            groups: state.groups.groupsMap.toArray().sort((a, b) => {
                return (+b.timestamp - +a.timestamp)
            }),
            loading: state.groups.loading,
            currentUser: currentUser,
            userRole: currentUser?.userRole || 'student',
            uId: userId || currentUser?.id,
            user
        }
    }));

    let isTeacher = (user?.userRole == 'teacher');

    const createInputRef = useRef();
    const dispatch = useDispatch();
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [joinModalVisible, setJoinModalVisible] = useState(false);

    useEffect(() => {
        dispatch(actions.loadAllGroups(uId));
        dispatch(usersActions.loadUserById(uId));
    }, [uId]);

    let isLoaderVisible = ((groups.length == 0) && (loading == true));

    if (isLoaderVisible == true) {
        return (
            <LoaderWrapper>
                <div style={{height: '60vh'}}>
                    <LottieLoader
                        json={group_loading_lottie}
                    />
                </div>
                <div>
                    {translate('loading')}
                </div>
            </LoaderWrapper>
        )
    }
    let vGroups = groups;
    if (isTeacher == true){
        vGroups = vGroups.filter(x => (x?.ownerId == user?.id));
    }

    return (
        <Wrapper>

            {groups.length == 0 ? null :
                <TopPlaceholder>
                    <TopHeading>
                        {translate('groups')}
                    </TopHeading>
                    <CreateGroupButton onClick={() => {
                        if (isTeacher) {
                            setAddModalVisible(true);
                        } else {
                            setJoinModalVisible(true);
                        }
                    }}>
                        <div>
                            {isTeacher ? translate('add_group') : 'Вступить в группу'}
                        </div>
                    </CreateGroupButton>
                </TopPlaceholder>
            }

            {!(loading == false && groups.length == 0) ? null :
                <LoaderWrapper>
                    <div style={{height: '60vh'}}>
                        <LottieLoader
                            json={not_groups_lottie}
                        />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{
                            fontSize: 16,
                            opacity: 0.75,
                            padding: 10,
                            textAlign: 'center',
                            marginTop: -20,
                            marginBottom: 10
                        }}>
                            Добро пожаловать!
                            <br/>
                            {userRole == 'student' ? 'Для начала работы нужно добавиться хотя бы в одну группу.' : 'Для начала работы нужно создать группу и добавить в неё студентов.'}
                        </div>
                        {userRole == 'student' ?
                            <BlueButton onClick={() => {
                                setJoinModalVisible(true);
                            }}>
                                Вступить в группу
                            </BlueButton>
                            :
                            <BlueButton onClick={() => {
                                setAddModalVisible(true);
                            }}>
                                Создать первую группу
                            </BlueButton>
                        }
                    </div>
                </LoaderWrapper>
            }

            <GroupsList>
                {vGroups.map((g, k) => {
                    let {name, users = [], invitationCode} = g;
                    return (
                        <Box key={g.id} onClick={() => {
                            ls('gr_mode', 'students');
                            // CommonHelper.linkTo(`/groups/${g.id}`, true);
                            CommonHelper.linkTo(`/groups/${g.id}`);
                        }}>
                            <BoxTop>
                                <BoxGroupImagePlaceholder>
                                    <PeopleImg src={peopleImg}/>
                                </BoxGroupImagePlaceholder>
                                <BoxTopName>
                                    {name}
                                </BoxTopName>
                            </BoxTop>

                            <FacesPlaceholder>
                                {users.length == 0 ?
                                    <VoidStudentsPlaceholder>
                                        {translate('no_students')}
                                    </VoidStudentsPlaceholder>
                                    :
                                    <FacesListTool faces={users}/>
                                }
                            </FacesPlaceholder>

                            <GroupInfoPlaceholder>

                                <GroupInfoRow>
                                    <img src={grLevelImg}
                                         style={{width: 21, height: 27, marginRight: 10}}/>
                                    <GroupInfoSpan style={{marginRight: 10}}>
                                        Код:
                                    </GroupInfoSpan>
                                    <GroupInfoSpan style={{color: 'rgba(31, 32, 65, 0.9)'}}>
                                        {invitationCode}
                                    </GroupInfoSpan>
                                </GroupInfoRow>

                            </GroupInfoPlaceholder>

                        </Box>

                    )
                })}
            </GroupsList>

            {addModalVisible == false ? null :
                <NiceModal
                    width={520}
                    onClose={() => {
                        setAddModalVisible(false);
                    }}>

                    <AddInner>

                        <AddInnerHeader>
                            {translate('create_group')}
                        </AddInnerHeader>

                        <Field>
                            <Input ref={createInputRef} placeholder={'Название'}/>
                        </Field>
                        <Field style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            {loading == true ? <Spin visible={true}/> :
                                <CreateGroupButton
                                    appearance={'primary'}
                                    onClick={async () => {
                                        if (loading == true) {
                                            return;
                                        }
                                        let pld = await dispatch(actions.createGroup(createInputRef.current.value));
                                        setAddModalVisible(false);
                                        CommonHelper.linkTo(`/groups/${pld?.group?.id}`);
                                    }}>
                                    {translate('create_group')}
                                </CreateGroupButton>
                            }
                        </Field>

                    </AddInner>

                </NiceModal>
            }

            {joinModalVisible == false ? null :
                <NiceModal
                    width={520}
                    onClose={() => {
                        setJoinModalVisible(false);
                    }}>

                    <AddInner>

                        <AddInnerHeader>
                            Добавиться в группу
                        </AddInnerHeader>

                        <Field>
                            <Input ref={createInputRef} placeholder={'Код группы'}/>
                        </Field>
                        <Field style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            {loading == true ? <Spin visible={true}/> :
                                <CreateGroupButton
                                    appearance={'primary'}
                                    onClick={async () => {
                                        if (loading == true) {
                                            return;
                                        }
                                        dispatch(actions.addToGroup(createInputRef.current.value));
                                        setJoinModalVisible(false);
                                    }}>
                                    Вступить
                                </CreateGroupButton>
                            }
                        </Field>

                    </AddInner>

                </NiceModal>
            }

        </Wrapper>
    );
}

const LoaderWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 24px;
`;

const Field = styled.div`
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;

  @media (max-width: 720px) {
    padding-bottom: 30%;
  }
`;

const AddInnerHeader = styled.div`
  text-align: center;
  letter-spacing: 0.166667px;
  color: #333539;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 29px;
`;

const AddInner = styled.div`
  width: 440px;
  @media (max-width: 640px) {
    width: 100%;
  }
`;

const GroupInfoPlaceholder = styled.div`
  margin-top: 20px;
`;

const GroupInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;

  :first-of-type {
    margin-bottom: 17px;
  }

  @media (max-width: 1220px) {
    font-size: 14px;
  }
`;

const GroupInfoSpan = styled.span`
  letter-spacing: 0.171429px;
  color: rgba(31, 32, 65, 0.5);
`;

const GroupsList = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const VoidStudentsPlaceholder = styled.div`
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 0.171429px;
  color: rgba(31, 32, 65, 0.7);
`;

const GroupItem = styled.div`
  background: white;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const InfoPlaceholder = styled.div`
  cursor: pointer;

  :hover {
    opacity: 0.5;
  }
`;

const Name = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
`;

const LineStats = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const StatsPlaceholder = styled.div`

`;

const CodeSubPlaceholder = styled.div`

`;

const CodePlaceholder = styled.div`
  font-size: 12px;
  opacity: 0.5;
`;

const UImg = styled.img`
  height: 16px;
`;

const TopPlaceholder = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopHeading = styled.div`
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.166667px;
  color: #333539;
`;

const CreateGroupButton = styled.div`
  background: #FF624F;
  border-radius: 1000px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 36px;
  padding-right: 36px;
  height: 44px;
  cursor: pointer;

  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;

  :hover {
    opacity: 0.8;
  }
`;

const Box = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(31, 32, 65, 0.05);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 30px;
  margin: 20px;

  cursor: pointer;


  :hover {
    opacity: 0.8;
  }
`;

const BoxTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 18px;
`;

const BoxGroupImagePlaceholder = styled.div`
  background: #A36EFF;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(31, 32, 65, 0.1);
  border-radius: 32px;
  width: 64px;
  min-width: 64px;
  height: 64px;
  margin-right: 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BoxTopName = styled.div`
  font-weight: bold;
  font-size: 19px;
  line-height: 23px;
  color: #1F2041;
  box-sizing: border-box;
  overflow: hidden;
  word-break: break-all;
`;

const PeopleImg = styled.img`
  width: 31px;
`;

const FacesPlaceholder = styled.div`
  margin-top: 18px;
`;