import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";
import VerfactoTracker from "./TrackerAPI";

const ExercisesAPI = {

    async getExercise(id) { // full
        let arr = (await axios.get(`${API_ENDPOINT}/v3/exercise/${id}`)).data.result;
        return arr;
    },

    async getAnswersStats(from, to) {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/wise-answers-stats`, {
            params: {
                from: from,
                to: to
            }
        })).data.result;
        return pld;
    },

    async getGroupAnswersStats(groupId, from, to) {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/groups/${groupId}/wise-answers-stats`, {
            params: {
                from: from,
                to: to
            }
        })).data.result;
        return pld;
    },

    async getStudentAnswersStats(studentId, from, to) {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/students/${studentId}/wise-answers-stats`, {
            params: {
                from: from,
                to: to
            }
        })).data.result;
        return pld;
    },

    async getExercisesByIds(ids = []) { // full
        let arr = (await axios.post(`${API_ENDPOINT}/v3/exercises-by-ids`, {
            ids: ids
        })).data.result;
        return arr;
    },

    async getAllExercises() { // full
        let arr = (await axios.get(`${API_ENDPOINT}/v3/all-exercises`)).data.result;
        return arr;
    },

    async createExercise(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/exercises`, data)).data.result;
        return pld;
    },

    async updateExercise(data) {
        let pld = (await axios.put(`${API_ENDPOINT}/v3/exercises`, data)).data.result;
        return pld;
    },

    async createExerciseCard(data) {
        if (data?.exerciseId == undefined) {
            window.alert('exerciseId is not provided');
            return;
        }
        let pld = (await axios.post(`${API_ENDPOINT}/v3/exercises-cards`, data)).data.result;
        return pld;
    },

    async updateExerciseCard(data) {
        console.log('updateExerciseCard: data = ', data);
        let pld = (await axios.put(`${API_ENDPOINT}/v3/exercises-cards`, data)).data.result;
        return pld;
    },

    async deleteExerciseCard(id) {
        let pld = (await axios.delete(`${API_ENDPOINT}/v3/exercises-cards/${id}`)).data.result;
        return pld;
    },

    async deleteExercise(id) {
        //todo: implement
    },

    /*
        options = {
            "foo": "bar"
        }
     */
    async saveExerciseAnswer(exerciseId, cardId, answer, options = {}) {
        console.log('saveExerciseAnswer: options = ', options);
        VerfactoTracker.track('EXERCISE_ANSWER', {exerciseId: exerciseId, cardId: cardId})
        let pld = (await axios.post(`${API_ENDPOINT}/v3/exercises/${exerciseId}/answers/card/${cardId}`, {
            answer: answer,
            ...options
        })).data.result;
        return pld;
    },

    async deleteExerciseAnswers(exerciseId, userId) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/exercises/${exerciseId}/clean-user-answers/${userId}`)).data.result;
        return pld;
    },


    async saveLessonTaskFileAnswer(taskId, url, options = {}) {
        VerfactoTracker.track('ATTACHMENT_UPLOADED', {taskId: taskId, url: url})
        let pld = (await axios.post(`${API_ENDPOINT}/v3/task/${taskId}/file-answer`, {
            url: url
        }, {
            params: {
                ...options
            }
        })).data.result;
        return pld;
    },

    async getLessonTaskFileAnswer(taskId, userId) {
        VerfactoTracker.track('ATTACHMENT_UPLOADED', {taskId: taskId})
        let pld = (await axios.get(`${API_ENDPOINT}/v3/task/${taskId}/file-answer`, {
            params: {
                userId: userId
            }
        })).data.result;
        return pld;
    },


    async updateTeacherAnswer(d) {
        console.log('updateTeacherAnswer: d = ', d);
        let pld = (await axios.put(`${API_ENDPOINT}/v3/exercises/user-answer/${d.id}`, {
            teacherMark: +d.teacherMark,
            teacherComment: d.teacherComment,
            teacherPassed: d.teacherPassed,
            assessmentType: d.assessmentType || 'rating'
        })).data.result;
        return pld;
    },

    async getExerciseAnswers(exerciseId, userId) {
        let arr = (await axios.get(`${API_ENDPOINT}/v3/exercises/${exerciseId}/answers`, {
            params: {
                userId: userId
            }
        })).data.result;
        return arr;
    }


}

export default ExercisesAPI;
