import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Input} from "../../ira/ui/Inputs";
import {GreenButton} from "../../ira/ui/Buttons";
import Spin from "arui-feather/spin";
import {translate} from "../../../utils/Translate";
import Sidebar from "arui-feather/sidebar";
import UpdateAvatarTool from "../../avatar/tools/UpdateAvatarTool";
import NiceModal from "../../modals/NiceModal";

export default function UpdateProfileForm(props) {
    const {
        loading = false,
        onSave = d => {

        },
        hasTitle = true,
        isAbiturient = false
    } = props;

    const [firstName, setFirstName] = useState(props?.firstName ? props?.firstName : '');
    const [lastName, setLastName] = useState(props?.lastName ? props?.lastName : '');
    const [phone, setPhone] = useState(props?.phone ? props?.phone : '');
    const [avatar, setAvatar] = useState(props?.avatar);
    const [avatarModalVisible, setAvatarModalVisible] = useState(false);

    const [city, setCity] = useState(props?.city ? props?.city : '');
    const [eduOrgName, setEduOrgName] = useState(props?.eduOrgName ? props?.eduOrgName : '');
    const [classNumber, setClassNumber] = useState(props?.classNumber ? props?.classNumber : '');
    const [teacherName, setTeacherName] = useState(props?.teacherName ? props?.teacherName : '');
    const [teacherEmail, setTeacherEmail] = useState(props?.teacherEmail ? props?.teacherEmail : '');
    const [foreignLang, setForeignLang] = useState(props?.foreignLang ? props?.foreignLang : '');

    return (
        <Wrapper>

            {hasTitle == false ? null :
                <Heading>
                    {translate('profile_update')}
                </Heading>
            }

            <Row>
                <Label>
                    {translate('name')}
                </Label>
                <Input
                    value={firstName}
                    onChange={evt => {
                        setFirstName(evt.target.value);
                    }}
                />
            </Row>

            <Row>
                <Label>
                    {translate('last_name')}
                </Label>
                <Input
                    value={lastName}
                    onChange={evt => {
                        setLastName(evt.target.value);
                    }}
                />
            </Row>

            <Row>
                <Label>
                    {translate('phone')}
                </Label>
                <Input
                    value={phone}
                    onChange={evt => {
                        setPhone(evt.target.value);
                    }}
                />
            </Row>

            {isAbiturient == false ? null :
                <>
                    <Row>
                        <Label>
                            Город
                        </Label>
                        <Input
                            value={city}
                            onChange={evt => {
                                setCity(evt.target.value);
                            }}
                        />
                    </Row>

                    <Row>
                        <Label>
                            Полное название вашего образовательного учреждения
                        </Label>
                        <Input
                            value={eduOrgName}
                            onChange={evt => {
                                setEduOrgName(evt.target.value);
                            }}
                        />
                    </Row>

                    <Row>
                        <Label>
                            Класс, в котором вы учитесь
                        </Label>
                        <Input
                            value={classNumber}
                            onChange={evt => {
                                setClassNumber(evt.target.value);
                            }}
                        />
                    </Row>

                    <Row>
                        <Label>
                            Имя, отчество и фамилия учителя, который может подтвердить, что вы школьник 9-11 класса
                        </Label>
                        <Input
                            value={teacherName}
                            onChange={evt => {
                                setTeacherName(evt.target.value);
                            }}
                        />
                    </Row>

                    <Row>
                        <Label>
                            Электронный адрес учителя, который может подтвердить, что вы школьник 9-11 класса
                        </Label>
                        <Input
                            value={teacherEmail}
                            onChange={evt => {
                                setTeacherEmail(evt.target.value);
                            }}
                        />
                    </Row>

                    <Row>
                        <Label>
                            Иностранный язык, который вы изучаете (можно указать несколько)
                        </Label>
                        <Input
                            value={foreignLang}
                            onChange={evt => {
                                setForeignLang(evt.target.value);
                            }}
                        />
                    </Row>

                </>
            }

            <Row>
                <Label>
                    {translate('avatar')}
                </Label>
                <div>
                    {avatar == undefined ? 'No avatar' :
                        <Avatar src={avatar}/>
                    }
                </div>
                <ChangeAvatarSpan onClick={() => {
                    setAvatarModalVisible(true);
                }}>
                    изменить аватар
                </ChangeAvatarSpan>
            </Row>

            <BottomButtonPlaceholder>
                <GreenButton onClick={() => {
                    onSave({
                        firstName,
                        lastName,
                        phone,
                        avatar,

                        city,
                        eduOrgName,
                        teacherName,
                        teacherEmail,
                        classNumber: `${classNumber}`,
                        foreignLang,

                    });
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null :
                        translate('save')
                    }
                </GreenButton>
            </BottomButtonPlaceholder>

            {avatarModalVisible == false ? null :
                <NiceModal
                    width={window.innerWidth}
                    visible={avatarModalVisible}
                    onClose={() => {
                        setAvatarModalVisible(false);
                    }}
                >

                    <AvatarInner>
                        <UpdateAvatarTool
                            avatar={avatar}
                            onChange={s => {
                                setAvatar(s);
                                setAvatarModalVisible(false);
                            }}
                        />
                    </AvatarInner>

                </NiceModal>
            }

        </Wrapper>
    );
}

const AvatarInner = styled.div`
  width: 520px;
  max-height: calc(100vh - 120px);

  @media (max-width: 720px) {
    width: 95%;
  }
`;

const ChangeAvatarSpan = styled.span`
  opacity: 0.7;
  cursor: pointer;

  :hover {
    opacity: 1;
  } 
`;

const Avatar = styled.div`
  width: 160px;
  height: 160px;
  background-color: whitesmoke;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
`;

const Heading = styled.div`
  margin-bottom: 20px;
  font-size: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid whitesmoke;
`;

const Wrapper = styled.div`

`;

const BottomButtonPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-weight: bold;
`;