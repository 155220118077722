import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import DialogViewTool from "../tools/DialogViewTool";
import DialogAnswerTool from "../tools/DialogAnswerTool";
import {API_ENDPOINT} from "../../../constants/config";
import DialogAPI from "../../../api/DialogAPI";
import CommonHelper from "../../../helpers/CommonHelper";
import share_img from "../../../assets/img/share_mini.svg";
import {translate} from "../../../utils/Translate";
import ViewAuthorTool from "../../author/tools/ViewAuthorTool";

export default function DialogExercisePanel(props) {
    const {id, userId} = props;

    const [dialog, setDialog] = useState();
    const [selectedRoleNumber, setSelectedRoleNumber] = useState();
    const [userAnswer, setUserAnswer] = useState();
    const [loading, setLoading] = useState(false);

    const {
        currentUser,
        isTeacher
    } = useMappedState(useCallback(state => {
        let user = state.users.usersMap.get(state.users.currentUserId);
        let isTeacher = (user?.userRole == 'teacher');
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            isTeacher: isTeacher
        }
    }));

    useEffect(() => {
        setLoading(true);
        DialogAPI.getDialog(id, userId).then(pld => {
            setLoading(false);
            setDialog(pld.dialog);
            setUserAnswer(pld.answer);
        }).catch(err => {
            console.log('caught error = ', err);
            setLoading(false);
        })
    }, [id, userId]);

    let viewData = {
        firstRole: {
            name: dialog?.firstRoleName,
            avatar: dialog?.firstRoleImg
        },
        secondRole: {
            name: dialog?.secondRoleName,
            avatar: dialog?.secondRoleImg
        },
        messages: dialog?.messages == undefined ? [] : dialog?.messages,
        name: dialog?.name,
        avatar: dialog?.avatar,
        description: dialog?.description,
        studentTask: dialog?.studentTask,
        videoUrl: dialog?.url
    };
    let selectedRole = (selectedRoleNumber == undefined) ? undefined : (selectedRoleNumber == 0 ? viewData.firstRole : viewData.secondRole);
    let answersMap = (userAnswer == undefined) ? {} : (userAnswer.answersMap || {});

    return (
        <Wrapper>

            <VeryTopPlaceholder>
                {dialog?.name == undefined || dialog?.name == '' ? null :
                    <NamePlaceholder>
                        {dialog?.name}
                    </NamePlaceholder>
                }
                {dialog?.description == undefined || dialog?.description == '' ? null :
                    <DescriptionPlaceholder>
                        {dialog?.description}
                    </DescriptionPlaceholder>
                }
                {dialog?.studentTask == undefined || dialog?.studentTask == '' ? null :
                    <DescriptionPlaceholder>
                        <div dangerouslySetInnerHTML={{__html: dialog?.studentTask.replace(/\n/g, '<br/>')}}></div>
                    </DescriptionPlaceholder>
                }
            </VeryTopPlaceholder>

            {selectedRoleNumber != undefined ? null :
                <div>
                    <DialogViewTool {...viewData}
                                    userId={currentUser?.id}
                                    onRoleSelect={num => {
                                        setSelectedRoleNumber(num);
                                    }}/>
                </div>
            }

            {selectedRoleNumber == undefined ? null :
                <div>
                    <DialogAnswerTool
                        {...viewData}
                        answersMap={answersMap}
                        selectedRoleNumber={selectedRoleNumber}
                        loading={loading}
                        onBack={() => {
                            setSelectedRoleNumber(undefined);
                        }}
                        onAnswersMapChange={async newMap => {
                            setLoading(true);
                            let pld = (await axios.post(`${API_ENDPOINT}/dialog/${id}/answer/${userId}`, {
                                answersMap: newMap
                            })).data;
                            setLoading(false);
                        }}
                    />
                </div>
            }

            {dialog == undefined ? null :
                <div style={{textAlign: 'center'}} >
                    <ViewAuthorTool
                        badgeMode={false}
                        userId={dialog?.userId || dialog?.creatorId}
                    />
                </div>
            }


        </Wrapper>
    );
}


const Wrapper = styled.div`

`;

const VeryTopPlaceholder = styled.div`
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid whitesmoke;
`;

const NamePlaceholder = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
`;

const DescriptionPlaceholder = styled.div`
  opacity: 0.6;
  margin-bottom: 15px;
`;

const ErrorPlaceholder = styled.div`
  padding: 5px;
  text-align: center;
  color: red;
`;