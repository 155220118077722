import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import VocabularyAPI from "../../../api/VocabularyAPI";

import plus_icon from '../../../assets/img/orange_plus.svg'
import left_arrow from '../../../assets/img/upward-arrow.svg'
import NiceModal from "../../modals/NiceModal";
import AddNewVocabularyWordPanel from "./AddNewVocabularyWordPanel";
import VocabularyItemPanel from "./VocabularyItemPanel";

const renderPaginator = (items, pageSize, currentPage, onCurrentPageChange = x => {
}) => {
    let totalNumber = (Math.ceil(1.0 * items.length / pageSize));
    if (totalNumber <= 1) {
        return null;
    }
    return (
        <PaginatorPlaceholder>
            <PaginatorSide>
                <ArrowImg
                    style={{transform: 'rotate(-90deg)'}}
                    src={left_arrow}
                    onClick={() => {
                        onCurrentPageChange(Math.max(currentPage - 1, 0));
                    }}
                />
            </PaginatorSide>
            <PaginatorCenter>
                {`${+currentPage + 1} / ${totalNumber}`}
            </PaginatorCenter>
            <PaginatorSide>
                <ArrowImg
                    style={{transform: 'rotate(90deg)'}}
                    src={left_arrow}
                    onClick={() => {
                        onCurrentPageChange(Math.min(currentPage + 1, totalNumber - 1));
                    }}
                />
            </PaginatorSide>
        </PaginatorPlaceholder>
    )
}

export default function UserVocabularyPanel(props) {
    const {
        userId,
        pageSize = 20,
        canAddWord = true,
        searchable = true
    } = props;

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedItemId, setSelectedItemId] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        VocabularyAPI.getUserWords(userId).then(arr => {
            setItems(arr);
            setLoading(false);
        })
    }, [userId]);

    if (loading == true) {
        return (
            <Code/>
        )
    }
    let s = searchQuery.trim().toLowerCase();
    let filteredItems = items.sort((a, b) => (+b.timestamp - +a.timestamp)).filter(it => {
        let {name} = it;
        let s1 = name.trim().toLowerCase();
        return (s1.indexOf(s) > -1);
    });
    let visibleItems = filteredItems.slice((currentPage * pageSize), ((currentPage + 1) * pageSize));
    let selectedItem = (selectedItemId == undefined) ? undefined : items.filter(it => (it.id == selectedItemId))[0];

    return (
        <Wrapper>

            {searchable == false ? null :
                <SearchPlaceholder>
                    <SearchInput placeholder={'Поиск'}
                                 onChange={evt => {
                                     setSearchQuery(evt.target.value);
                                     setCurrentPage(0);
                                 }}
                    />

                    {canAddWord == false ? null :
                        <AddImage src={plus_icon} onClick={() => {
                            setAddModalVisible(true);
                        }}/>
                    }

                </SearchPlaceholder>
            }

            <ItemsList>
                {visibleItems.map((item, k) => {
                    let {name, meanings, id} = item;
                    return (
                        <Item key={id} onClick={() => {
                            setSelectedItemId(id);
                        }}>
                            <Half>
                                <Name>
                                    {name}
                                </Name>
                            </Half>
                            <Half align={'right'}>
                                <MeaningsPlaceholder>
                                    {meanings.join(', ')}
                                </MeaningsPlaceholder>
                            </Half>
                        </Item>
                    )
                })}
            </ItemsList>

            {renderPaginator(items, pageSize, currentPage, x => {
                setCurrentPage(x);
            })}

            <ExportPlaceholder>
                <ExportSpan onClick={() => {
                    let text = items.map(x => x.name).join('\n');
                    let element = document.createElement('a');
                    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
                    element.setAttribute('download', `vocabulary.txt`);
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                }}>
                    Export
                </ExportSpan>
            </ExportPlaceholder>

            {(items.length == 0 && searchQuery != undefined && searchQuery.trim().length > 0) ?
                <NoWordsPlaceholder>
                    По запросу "<b>{searchQuery}</b>" ничего не найдено
                </NoWordsPlaceholder> : null
            }

            {selectedItem == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedItemId(undefined);
                }}>

                    <ModalInner>
                        <VocabularyItemPanel item={selectedItem}/>
                    </ModalInner>

                </NiceModal>
            }

            {addModalVisible == false ? null :
                <NiceModal onClose={async () => {
                    let arr = await VocabularyAPI.getUserWords(userId);
                    setItems(arr);
                    setAddModalVisible(false);
                }}>

                    <AddModalInner>
                        <AddNewVocabularyWordPanel {...props} onAdded={async () => {
                            let arr = await VocabularyAPI.getUserWords(userId);
                            setItems(arr);
                            setAddModalVisible(false);
                        }}/>
                    </AddModalInner>

                </NiceModal>
            }


        </Wrapper>
    );
}

const ExportPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const ExportSpan = styled.div`
  cursor: pointer;
  opacity: 0.75;
  font-style: italic;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  background-color: white;
`;

const AddModalInner = styled.div`
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto;
  min-width: 420px;

  @media (max-width: 720px){
    min-width: 0px;
  }
`;

const SearchPlaceholder = styled.div`
  position: relative;
`;

const PaginatorPlaceholder = styled.div`
  padding: 5px;
  margin-top: 5px;
  border-top: 1px solid whitesmoke;
  text-align: center;
`;

const PaginatorSide = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 30px
`;

const AddImage = styled.img`
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding-left: 5px;
  border: none;
  border-bottom: 1px solid whitesmoke;
  outline: none;
  height: 32px;
  line-height: 32px;
  font-size: 16px;

  :focus {
    border-color: blue;
  }
`;

const ArrowImg = styled.img`
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
  opacity: 0.6;

  :hover {
    opacity: 1;
  }
`;

const PaginatorCenter = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 80px;
`;

const ItemsList = styled.div`

`;

const Item = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px;
  border-bottom: 1px solid whitesmoke;

  :hover {
    opacity: 0.8;
    background-color: rgba(0, 112, 221, 0.06);
  }
`;

const rightWidth = 200;

const Half = styled.div`
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  width: 50%;
  text-align: ${props => (props.align == 'right' ? 'right' : 'left')};
`;

const ItemLeft = styled.div`
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  width: calc(100% - ${rightWidth}px);
`;

const ItemRight = styled.div`
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  width: ${rightWidth}px;
  text-align: right;
`;

const Name = styled.div`
  font-weight: bold;
`;

const MeaningsPlaceholder = styled.div`
  opacity: 0.8;
  font-style: italic;
`;

const ModalInner = styled.div`
  padding: 10px;
  padding-bottom: 30px;
  min-width: 420px;
`;

const NoWordsPlaceholder = styled.div`
  text-align: center;
  padding: 20px;
  opacity: 0.5;
  font-style: italic;
  font-size: 20px;
  line-height: 28px;
`;