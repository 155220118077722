import ExercisesAPI from '../../api/ExercisesAPI'

import * as types from '../ActionTypes'

let loadExercises_ = () => {
    return {
        type: types.LOAD_EXERCISES
    }
}
let loadExercisesSuccess = (exercises) => {
    return {
        type: types.LOAD_EXERCISES_SUCCESS,
        exercises: exercises
    }
}
let loadExercisesFailed = (error) => {
    return {
        type: types.LOAD_EXERCISES_FAIL,
        error: error
    }
}

//thunk
export function getExercisesByIds(exercisesIds = []) {
    return (dispatch, getState) => {
        dispatch(loadExercises_());
        return ExercisesAPI.getExercisesByIds(exercisesIds).then(
            exercises => dispatch(loadExercisesSuccess(exercises)),
            error => dispatch(loadExercisesFailed(error))
        )
    }
}

export function getAllExercises() {
    return (dispatch, getState) => {
        dispatch(loadExercises_());
        return ExercisesAPI.getAllExercises().then(
            exercises => dispatch(loadExercisesSuccess(exercises)),
            error => dispatch(loadExercisesFailed(error))
        )
    }
}

export function loadExerciseById(id) {
    return (dispatch, getState) => {
        dispatch(loadExercises_());
        return ExercisesAPI.getExercise(id).then(
            exercise => dispatch(loadExercisesSuccess([exercise])),
            error => dispatch(loadExercisesFailed(error))
        )
    }
}

let createExercise_ = () => {
    return {
        type: types.CREATE_EXERCISE
    }
}
let createExerciseSuccess = (exercise) => {
    return {
        type: types.CREATE_EXERCISE_SUCCESS,
        exercise: exercise
    }
}
let createExerciseFailed = (error) => {
    return {
        type: types.CREATE_EXERCISE_FAIL,
        error: error
    }
}

//thunk
export function createExercise(data) {
    return (dispatch, getState) => {
        dispatch(createExercise_());
        return ExercisesAPI.createExercise(data).then(
            exercise => dispatch(createExerciseSuccess(exercise)),
            error => dispatch(createExerciseFailed(error))
        )
    }
}

export function selectExercise(id) {
    return {
        type: types.SELECT_EXERCISE,
        id: id
    }
}

let updateExercise_ = () => {
    return {
        type: types.UPDATE_EXERCISE
    }
}
let updateExerciseSuccess = (exercise) => {
    return {
        type: types.UPDATE_EXERCISE_SUCCESS,
        exercise: exercise
    }
}
let updateExerciseFailed = (error) => {
    return {
        type: types.UPDATE_EXERCISE_FAIL,
        error: error
    }
}

//thunk
export function updateExercise(data) {
    return (dispatch, getState) => {
        dispatch(updateExercise_());
        return ExercisesAPI.updateExercise(data).then(
            exercise => dispatch(updateExerciseSuccess(exercise)),
            error => dispatch(updateExerciseFailed(error))
        )
    }
}

let deleteExercise_ = () => {
    return {
        type: types.DELETE_EXERCISE
    }
}
let deleteExerciseSuccess = (id) => {
    return {
        type: types.DELETE_EXERCISE_SUCCESS,
        id: id
    }
}
let deleteExerciseFailed = (error) => {
    return {
        type: types.DELETE_EXERCISE_FAIL,
        error: error
    }
}

//thunk
export function deleteExercise(id) {
    return (dispatch, getState) => {
        dispatch(deleteExercise_());
        return ExercisesAPI.deleteExercise(id).then(
            () => dispatch(deleteExerciseSuccess(id)),
            error => dispatch(deleteExerciseFailed(error))
        )
    }
}
