import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useReducer,
    useMemo,
    forwardRef,
    useImperativeHandle
} from 'react';
import styled from 'styled-components';
import Spin from 'arui-feather/spin'

import {ReactMic} from '../aliens/rect-mic';

import {BlueButton, GreenButton, RedButton} from "../ira/ui/Buttons";
import RecorderAPI from "../../api/RecorderAPI";
import ReactHelper from "../../helpers/ReactHelper";
import CommonHelper from "../../helpers/CommonHelper";
import VerfactoTracker from "../../api/TrackerAPI";
import {MicrophoneRecorder} from "../aliens/rect-mic/libs/MicrophoneRecorder";

// const TreadmillRecordComponent = forwardRef((props, ref) => {
export default function TreadmillRecordComponent(props) {
    const {
        id,
        recordingTime = 0,
        onRecorded = blob => {

        },
        onSave = url => {

        },
        onStartRecording = () => {

        },
        onError = () => {

        },
        setUrl = url => {

        }
    } = props;

    function recorder(stream) {
        const mediaRecorder = new MediaRecorder(stream)
        mediaRecorder.start();
        console.log('recording started')

        setTimeout(() => {
            mediaRecorder.stop();
            console.log('recording stopped')
        }, recordingTime * 1000)

        let chunks = []

        mediaRecorder.ondataavailable = (evt) => {
            chunks.push(evt.data);
        };

        mediaRecorder.onstop = (e) => {
            const blob = new Blob(chunks, {type: "audio/ogg; codecs=opus"});
            console.log('chunks: ', chunks);
            chunks = [];
            console.log('blob: ', blob);
            setUrl(blob)
            // setUrl(blob.current?.blob);
        }
    }

    useEffect(() => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            console.log("getUserMedia supported.");
            navigator.mediaDevices
                .getUserMedia(
                    // constraints - only audio needed for this app
                    {
                        audio: true,
                    }
                )

                // Success callback
                .then((stream) => recorder(stream))

                // Error callback
                .catch((err) => {
                    console.error(`The following getUserMedia error occurred: ${err}`);
                    onError();
                });
        } else {
            console.log("getUserMedia not supported on your browser!");
            onError();
        }
    }, [id])

    const [recording, setRecording] = useState(false);

    const blobRef = useRef();

    return (
        <Wrapper>
            <WavePlaceholder>
            </WavePlaceholder>
        </Wrapper>
    );
}
// })

// export default TreadmillRecordComponent;

const Wrapper = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WavePlaceholder = styled.div`
  width: 360px;
  height: 90px;
  margin: 0 auto;
`;