import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {DEFAULT_VIDEO_PLACEHOLDER} from "../../../constants/config";
import ReactPlayer from "react-player";
import TranslatableParagraph from "../../translate/panels/TranslatableParagraph";
import ExercisePanel from "../../exercises/panels/ExercisePanel";
import DrochkaExercisePanel from "../../drochka_exercise/panels/DrochkaExercisePanel";
import ls from 'local-storage'
import VerfactoTracker from "../../../api/TrackerAPI";
import NiceModal from "../../modals/NiceModal";
import DialogExercisePanel from "../../dialogs/panels/DialogExercisePanel";
import TestsListInfoTool from "../../tests/tools/TestsListInfoTool";
import eye_no from "../../../assets/img/eye_no.svg";
import eye_yes from "../../../assets/img/eye_yes.svg";
import TreadmillsListViewTool from "../../treadmills_exercises/panels/TreadmillsListViewTool";
import QuestionnairePanel from "../../questionnaires/panels/QuestionnairePanel";
import SubtitlesPlayer from "../../materials/tools/SubtitlesPlayer";

import ImageViewer from 'react-simple-image-viewer';
import WrappedTranslatableParagraph from "../../translate/panels/WrappedTranslatableParagraph";
import {getFixedUrl} from "../../exercises/tools/ExerciseCardItemView";

const getPrettyUrl = url => {
    return `${url}`.replace('files.ru-spb-2.englishpatient.org', 'files.entsu.ru');
}

export default function SlideContentItemView(item) {
    const {
        type,
        moduleId,
        canShare = false,
        useTranslatableTranscript = true,
        isHidden = false,
        userId,
        selectedTaskId,
        onOnlineEyeToggle = eId => {

        },
        canToggleTranscriptEye = false,
        onlineHiddenItemsIds = [],
        onImageClick = imUrl => {

        }
    } = item;

    const [copyrightText, setCopyrightText] = useState(undefined);

    const [isOpen, setIsOpen] = useState(item.visible == undefined ? true : (item.visible));
    const showToggler = (item.visible == false);

    let copyrightDescription = (item.content?.copyrightDescription == undefined || item.content?.copyrightDescription == '') ? item.content?.copyrightUrl : item.content?.copyrightDescription;

    console.log('render: canToggleTranscriptEye = ', canToggleTranscriptEye);
    let canSeeTranscript = false;
    if (onlineHiddenItemsIds.indexOf(`tr-${item.id}`) == -1 || canToggleTranscriptEye == true) {
        canSeeTranscript = true;
    }

    return (
        <Wrapper style={{opacity: (isHidden == true) ? 0 : 1}}>

            {isOpen == false ? null :
                <div>
                    {item.type != 'image' ? null :
                        <div className={'image_placeholder'}>
                            <MyImg src={getPrettyUrl(item?.content?.url)} onClick={() => {
                                onImageClick(item?.content?.url);
                            }}/>
                            {((copyrightDescription == undefined) || (copyrightDescription.trim() == '')) ? null :
                                <CopyrightPlaceholder className={'transcript_placeholder'}>
                                    <CopyrightSpan onClick={() => {
                                        setCopyrightText(copyrightDescription);
                                    }}>
                                        источник
                                    </CopyrightSpan>
                                </CopyrightPlaceholder>
                            }
                        </div>
                    }

                    {(item.type != 'video' || item?.content?.url == undefined) ? null :
                        <div>

                            {(item.content.subtitles == undefined || item.content.subtitles.length == 0) ?
                                <VideoPlaceholder className={'video_placeholder'} key={item.content.url}>
                                    <ReactPlayer
                                        width={'100%'}
                                        height={'100%'}
                                        playing={true}
                                        light={getFixedUrl(item?.content?.avatar || DEFAULT_VIDEO_PLACEHOLDER)}
                                        controls={true}
                                        url={getPrettyUrl(item?.content?.url)}
                                        onPlay={() => {
                                            console.log('onPlay!');
                                            // let vKey = `vi_vf_${item?.content?.url}`;
                                            // if (ls(vKey) == undefined) {
                                            //     ls(vKey, 'yes');
                                            //     VerfactoTracker.track('VIDEO_PLAY', {url: item?.content?.url});
                                            // }
                                        }}
                                    />
                                </VideoPlaceholder>
                                :
                                <div style={{width: '100%'}}>
                                    <SubtitlesPlayer
                                        currentStyle={{fontSize: 24, marginTop: 0, marginBottom: 0}}
                                        sideStyle={{fontSize: 18}}
                                        {...item.content}
                                    />
                                </div>
                            }


                            {((item.content?.transcript == undefined) || (item.content.transcript.trim() == '')) ? null :
                                <TranscriptPlaceholder className={'transcript_placeholder'}
                                                       key={item.content.transcript}>
                                    {canSeeTranscript == false ? null :
                                        <>
                                            {useTranslatableTranscript == false && (item?.user?.userRole != 'student') ?
                                                <div
                                                    dangerouslySetInnerHTML={{__html: (item.content.transcript == undefined) ? '' : item.content.transcript.replace(/\n/g, '<br/>')}}>
                                                </div>
                                                :
                                                <WrappedTranslatableParagraph text={item.content.transcript}
                                                                              userId={item?.user?.id}/>}
                                        </>
                                    }


                                    {canToggleTranscriptEye == false ? null :
                                        <CopyImg
                                            src={onlineHiddenItemsIds.indexOf(`tr-${item.id}`) > -1 ? eye_no : eye_yes}
                                            style={{width: 14, height: 14}}
                                            onClick={() => {
                                                onOnlineEyeToggle(`tr-${item?.id}`);
                                            }}
                                        />
                                    }

                                </TranscriptPlaceholder>
                            }

                            {((copyrightDescription == undefined) || (copyrightDescription.trim() == '')) ? null :
                                <CopyrightPlaceholder className={'transcript_placeholder'}>
                                    <CopyrightSpan onClick={() => {
                                        setCopyrightText(copyrightDescription);
                                    }}>
                                        источник
                                    </CopyrightSpan>
                                </CopyrightPlaceholder>
                            }

                        </div>
                    }

                    {item.type != 'audio' ? null :
                        <div className={'audio_placeholder'} style={{height: 40, marginBottom: "25px"}}>
                            <audio controls style={{width: '100%', boxSizing: 'border-box'}}>
                                <source src={getPrettyUrl(item?.content?.url)}/>
                            </audio>
                            {((copyrightDescription == undefined) || (copyrightDescription.trim() == '')) ? null :
                                <CopyrightPlaceholder className={'transcript_placeholder'}>
                                    <CopyrightSpan onClick={() => {
                                        setCopyrightText(copyrightDescription);
                                    }}>
                                        источник
                                    </CopyrightSpan>
                                </CopyrightPlaceholder>
                            }
                        </div>
                    }

                    {((item.type != 'text')) ? null :
                        <div className={'text_placeholder'}>
                            <div dangerouslySetInnerHTML={{__html: item?.content?.text}}></div>
                            {((copyrightDescription == undefined) || (copyrightDescription.trim() == '')) ? null :
                                <CopyrightPlaceholder className={'transcript_placeholder'}>
                                    <CopyrightSpan onClick={() => {
                                        setCopyrightText(copyrightDescription);
                                    }}>
                                        источник
                                    </CopyrightSpan>
                                </CopyrightPlaceholder>
                            }
                        </div>
                    }

                    {((item.type != 'html')) ? null :
                        <div className={'html_placeholder'}>
                            <div dangerouslySetInnerHTML={{__html: item?.content}}></div>
                        </div>
                    }

                    {((item.type != 'exercise')) ? null :
                        <ExercisePlaceholder className={'exercise_placeholder'}>
                            <ExercisePanel
                                {...item}
                                canShare={canShare}
                                id={item.exerciseId}
                            />
                        </ExercisePlaceholder>
                    }

                    {((item.type != 'dialog')) ? null :
                        <ExercisePlaceholder className={'exercise_placeholder'}>
                            <DialogExercisePanel
                                {...item}
                                canShare={canShare}
                                id={item.exerciseId}
                            />
                        </ExercisePlaceholder>
                    }


                    {((item.type != 'drochka')) ? null :
                        <ExercisePlaceholder className={'exercise_placeholder'}>
                            <DrochkaExercisePanel
                                {...item}
                                id={item.exerciseId}
                            />
                        </ExercisePlaceholder>
                    }

                    {((item.type != 'questionnaire')) ? null :
                        <ExercisePlaceholder className={'exercise_placeholder'}>
                            <QuestionnairePanel
                                {...item}
                                id={item.exerciseId}
                            />
                        </ExercisePlaceholder>
                    }

                    {((item.type != 'test')) ? null :
                        <ExercisePlaceholder className={'exercise_placeholder'}>
                            <TestsListInfoTool
                                {...item}
                                ids={[item.exerciseId]}
                            />
                        </ExercisePlaceholder>
                    }

                    {((item.type != 'treadmill')) ? null :
                        <ExercisePlaceholder className={'exercise_placeholder'}>
                            <TreadmillsListViewTool
                                {...item}
                                haveSettings={selectedTaskId === moduleId}
                                isModule={true}
                                ids={[item.exerciseId]}
                            />
                        </ExercisePlaceholder>
                    }


                    {copyrightText == undefined ? null :
                        <NiceModal onClose={() => {
                            setCopyrightText(undefined);
                        }}>
                            <CpInnerPlaceholder>
                                <div
                                    dangerouslySetInnerHTML={{__html: (copyrightText == undefined ? '' : copyrightText).replace(/\n/g, '<br/>')}}></div>
                            </CpInnerPlaceholder>
                        </NiceModal>
                    }
                </div>
            }

            {showToggler == false ? null :
                <TogglerPlaceholder onClick={() => {
                    setIsOpen(!isOpen);
                }}>
                    {isOpen == false ?
                        <div>
                            {item.visibilityShowButtonName || 'Показать'}
                        </div>
                        :
                        <div>
                            {item.visibilityHideButtonName || 'Скрыть'}
                        </div>
                    }

                </TogglerPlaceholder>
            }

        </Wrapper>
    );
}

const TogglerPlaceholder = styled.div`
  padding: 7px;
  background: whitesmoke;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const CopyImg = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
  opacity: 0.8;
  position: absolute;
  right: 10px;
  top: 10px;

  :hover {
    opacity: 1;
  }
`;

const CpInnerPlaceholder = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: 75vw;


`;

const CopyrightPlaceholder = styled.div`
  text-align: right;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const CopyrightSpan = styled.span`
  font-size: 9px;
  opacity: 0.4;
  font-style: italic;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const ExercisePlaceholder = styled.div`

`;

const VideoPlaceholder = styled.div`
  height: 420px;
  width: 100%;
`;

const MyImg = styled.img`
  width: 100%;
  box-sizing: border-box;
`;

const TranscriptPlaceholder = styled.div`
  max-height: 160px;
  overflow-y: auto;
  padding: 10px;
  background-color: whitesmoke;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 14px;
  font-weight: normal;
  box-sizing: border-box;
  position: relative;
`;


const Video = styled.video`
  width: 100%;
  height: 400px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;