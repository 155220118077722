import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import LottieLoader from "../loaders/LottieLoader";

import loader_json from '../../assets/lottie/under_construction.json'
import TreadmillsPanel from "../treadmills_exercises/panels/TreadmillsPanel";

import AllActiveUsersStatsTool from "../stats/tools/AllActiveUsersStatsTool";
import SimpleAnalyticsPanel from "../analytics/panels/SimpleAnalyticsPanel";
import Tabs, {TabItem} from "../ui/Tabs";
import TeacherAnalyticsPanel from "../analytics/panels/TeacherAnalyticsPanel";
import TeachersAnalyticsTool from "../analytics/tools/TeachersAnalyticsTool";

export default function TeacherAnalyticsApp(props) {

    return (
        <TeacherTemplate active={'analytics'}>


            <Wrapper>

                <Tabs
                    tabs={[
                        {
                            label: 'Использование',
                            content: (
                                <TabItem>
                                    <SimpleAnalyticsPanel/>
                                </TabItem>
                            )
                        },
                        {
                            label: 'По преподавателям',
                            content: (
                                <TabItem>
                                    <Tabs
                                        tabs={[
                                            {
                                                label: 'Поиск',
                                                content: (
                                                    <TabItem>
                                                        <TeacherAnalyticsPanel/>
                                                    </TabItem>
                                                )
                                            },
                                            {
                                                label: 'Активность',
                                                content: (
                                                    <TabItem>
                                                        <TeachersAnalyticsTool/>
                                                    </TabItem>
                                                )
                                            }
                                        ]}
                                    />

                                </TabItem>
                            )
                        }
                    ]}
                />


            </Wrapper>


        </TeacherTemplate>
    );
}

const Wrapper = styled.div`
  padding: 20px;
`;

const ConstructionPlaceholder = styled.div`
  height: 80vh;
  text-align: center;
`;