import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SlideTextEditor from "./slite_item/SlideTextEditor";
import PolotnoTool from "../../polotno/tools/PolotnoTool";
import PhotoEditorPhotoPicker from "../../photos/tools/PhotoEditorPhotoPicker";
import LottieLoader from "../../loaders/LottieLoader";

import under_construction_json from '../../../assets/lottie/team-working-on-code-compilation.json'
import MaterialSearchPanel from "../../materials/panels/MaterialSearchPanel";
import UpdateAudioItemTool from "./UpdateAudioItemTool";
import UpdateMaterialForm from "../../materials/forms/UpdateMaterialForm";
import SearchOrCreateMaterialsPanel from "../../materials/panels/SearchOrCreateMaterialsPanel";
import SimpleImageUploader from "../../uploader/tools/SimpleImageUploader";
import ContentBasketPanel from "../../basket/ContentBasketPanel";

import Select from 'react-select'
import Tabs, {TabItem} from "../../ui/Tabs";
import ReactHelper from "../../../helpers/ReactHelper";
import {BlueButton} from "../../ira/ui/Buttons";


const VIS_OPTIONS = [
    {
        label: 'Да',
        value: true
    },
    {
        label: 'Нет',
        value: false
    }
]

export default function SlideItemEditTool(props) {
    const {
        loading = false,
        type,
        onChange = d => {

        },
        item = undefined,
        mode = 'add',
        selectedIds = [],

        hasVisibilitySettings = true

    } = props;

    console.log('SlideItemEditTool: render: item = ', item);

    const [hideButtonName, setHideButtonName] = useState(props.visibilityHideButtonName || 'Скрыть');
    const [showButtonName, setShowButtonName] = useState(props.visibilityShowButtonName || 'Показать');

    // const debouncedHideButtonName = ReactHelper.useDebounce(hideButtonName, 1000);
    // const debouncedShowButtonName = ReactHelper.useDebounce(showButtonName, 1000);

    // useEffect(
    //     () => {
    //         console.log('--->>> debouncedHideButtonName = ', debouncedHideButtonName);
    //         if (debouncedHideButtonName) {
    //             onChange({...item, visibilityHideButtonName: hideButtonName}, false);
    //         } else {
    //
    //         }
    //     },
    //     [debouncedHideButtonName] // Only call effect if debounced search term changes
    // );
    //
    // useEffect(
    //     () => {
    //         if (debouncedShowButtonName) {
    //             onChange({...item, visibilityShowButtonName: showButtonName}, false);
    //         } else {
    //
    //         }
    //     },
    //     [debouncedShowButtonName] // Only call effect if debounced search term changes
    // );

    return (
        <Wrapper>

            <Tabs
                tabs={[
                    {
                        label: 'Редактирование',
                        content: (
                            <TabItem>

                                {type != 'text' ? null :
                                    <TextEditorPlaceholder>

                                        <SlideTextEditor
                                            loading={loading}
                                            copyrightDescription={props?.item?.content?.copyrightDescription}
                                            html={props?.item?.content?.text}
                                            onChange={(newHtml, copDesc) => {
                                                let newCont = {
                                                    ...props?.content,
                                                    text: newHtml,
                                                    copyrightDescription: copDesc
                                                }
                                                onChange({...item, content: newCont});
                                            }}
                                        />
                                        {/*{JSON.stringify(props)}*/}

                                    </TextEditorPlaceholder>
                                }

                                {type != 'buffer' ? null :
                                    <BufferPlaceholder>
                                        <ContentBasketPanel
                                            shouldCopyToRedux={false}
                                            onSelect={a => {
                                                console.log('on select occured! a = ', a);
                                                onChange({
                                                    ...item,
                                                    type: a.type,
                                                    content: {
                                                        ...item?.content,
                                                        ...a
                                                    }
                                                });
                                            }}/>
                                    </BufferPlaceholder>
                                }

                                {type != 'image' ? null :
                                    <div style={{height: `calc(100vh - 130px)`}} >
                                        <PhotoEditorPhotoPicker
                                            loading={loading}
                                            url={props?.item?.content?.url}
                                            copyrightDescription={props?.item?.content?.copyrightDescription}
                                            hasCopyrightSection={true}
                                            onSave={(newUrl, copText) => {
                                                console.log('onSave: newUrl, copText = ', newUrl, copText);
                                                let newCont = {
                                                    ...newUrl?.content,
                                                    url: newUrl,
                                                    copyrightDescription: copText
                                                }
                                                onChange({...item, content: newCont});
                                            }}
                                        />
                                    </div>
                                }

                                {type != 'direct_image' ? null :
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <SimpleImageUploader onSave={x => {
                                            console.log('x = ', x);
                                            let newCont = {
                                                ...item?.content,
                                                url: x
                                            }
                                            console.log('newCont = ', newCont);
                                            onChange({...item, type: 'image', content: newCont});
                                        }}/>
                                    </div>
                                }


                                {type != 'exercise' ? null :
                                    <ExercisePlaceholder>
                                        <SearchOrCreateMaterialsPanel
                                            {...item}
                                            type={'exercises'}
                                            loading={loading}
                                            onSelect={ex => {
                                                onChange({...item, exerciseId: ex.id});
                                            }}
                                        />
                                    </ExercisePlaceholder>
                                }

                                {type != 'questionnaire' ? null :
                                    <ExercisePlaceholder>
                                        <SearchOrCreateMaterialsPanel
                                            {...item}
                                            type={'questionnaires'}
                                            loading={loading}
                                            onSelect={ex => {
                                                onChange({...item, exerciseId: ex.id});
                                            }}
                                        />
                                    </ExercisePlaceholder>
                                }


                                {type != 'treadmill' ? null :
                                    <ExercisePlaceholder>
                                        <SearchOrCreateMaterialsPanel
                                            {...item}
                                            type={'treadmills'}
                                            loading={loading}
                                            onSelect={ex => {
                                                onChange({...item, exerciseId: ex.id});
                                            }}
                                        />
                                    </ExercisePlaceholder>
                                }

                                {type != 'dialog' ? null :
                                    <ExercisePlaceholder>
                                        <SearchOrCreateMaterialsPanel
                                            {...item}
                                            type={'dialogs'}
                                            loading={loading}
                                            onSelect={ex => {
                                                onChange({...item, exerciseId: ex.id});
                                            }}
                                        />
                                    </ExercisePlaceholder>
                                }

                                {type != 'test' ? null :
                                    <ExercisePlaceholder>
                                        <SearchOrCreateMaterialsPanel
                                            {...item}
                                            type={'tests'}
                                            loading={loading}
                                            onSelect={ex => {
                                                onChange({...item, exerciseId: ex.id});
                                            }}
                                        />
                                    </ExercisePlaceholder>
                                }

                                {type != 'card' ? null :
                                    <ExercisePlaceholder>
                                        <SearchOrCreateMaterialsPanel
                                            {...item}
                                            type={'cards'}
                                            loading={loading}
                                            onSelect={ex => {
                                                onChange({...item, exerciseId: ex.id, type: 'drochka'});
                                            }}
                                        />
                                    </ExercisePlaceholder>
                                }

                                {type != 'video' ? null :
                                    <ExercisePlaceholder>
                                        {mode == 'add' ?
                                            <SearchOrCreateMaterialsPanel
                                                ids={selectedIds}
                                                type={'materials'}
                                                loading={loading}
                                                onSelect={v => {
                                                    console.log('v = ', v);
                                                    let newCont = {
                                                        ...props?.content,
                                                        url: v.url,
                                                        avatar: v.avatar,
                                                        id: v.id,
                                                        transcript: v.transcript,
                                                        copyrightDescription: v.copyrightDescription,
                                                        subtitles: v.subtitles || []
                                                    }
                                                    onChange({...item, content: newCont});
                                                }}
                                            />
                                            :
                                            <UpdateMaterialForm
                                                hasName={true}
                                                {...item?.content}
                                                onSave={d => {
                                                    let newCont = {
                                                        ...item?.content,
                                                        ...d
                                                    };
                                                    onChange({...item, content: newCont});
                                                }}
                                            />
                                        }
                                    </ExercisePlaceholder>
                                }

                                {type != 'audio' ? null :
                                    <AudioPlaceholder>
                                        <UpdateAudioItemTool
                                            url={item?.content?.url}
                                            copyrightDescription={item?.content?.copyrightDescription}
                                            onSave={v => {
                                                let newCont = {
                                                    ...props?.content,
                                                    url: v.url,
                                                    copyrightDescription: v.copyrightDescription
                                                }
                                                onChange({...item, content: newCont});
                                            }}
                                        />
                                    </AudioPlaceholder>
                                }

                                {['image', 'text', 'exercise', 'card', 'video', 'audio', 'dialog', 'test', 'questionnaire', 'direct_image', 'buffer', 'treadmill'].indexOf(type) > -1 ? null :
                                    <NotFoundPlaceholder>
                                        <LottieLoader json={under_construction_json}/>
                                        <UnderConstructionText>
                                            Under construction...
                                            <br/>
                                            <br/>
                                            Will be available 16.11.2021
                                        </UnderConstructionText>
                                    </NotFoundPlaceholder>
                                }

                            </TabItem>
                        )
                    },
                    (hasVisibilitySettings == false) ? undefined :
                        {
                            label: 'Видимость',
                            content: (
                                <TabItem>
                                    <div style={{marginBottom: 10}} >
                                        <div style={{fontWeight: 'bold', marginBottom: 5}} >
                                            Видно по умолчанию
                                        </div>
                                        <Select
                                            value={VIS_OPTIONS.filter(x => (x.value == (item?.visible == undefined ? true : item.visible)))[0]}
                                            options={VIS_OPTIONS}
                                            onChange={a => {
                                                onChange({...item, visible: a.value}, false);
                                            }}
                                        />
                                    </div>

                                    {item?.visible != false ? null :
                                        <div>
                                            <div style={{marginBottom: 15}} >
                                                <div style={{fontWeight: 'bold', marginBottom: 5}} >
                                                    Название кнопки открывания
                                                </div>
                                                <input value={showButtonName} placeholder={'Показать'}
                                                       onChange={evt => {
                                                           setShowButtonName(evt.target.value);
                                                       }}/>
                                            </div>
                                            <div>
                                                <div style={{fontWeight: 'bold', marginBottom: 5}} >
                                                    Название кнопки закрывания
                                                </div>
                                                <input value={hideButtonName} placeholder={'Скрыть'}
                                                       onChange={evt => {
                                                           setHideButtonName(evt.target.value);
                                                       }}/>
                                            </div>

                                            <div style={{marginTop: 20}} >
                                                <BlueButton onClick={() => {
                                                    onChange({
                                                        ...item,
                                                        visibilityHideButtonName: hideButtonName,
                                                        visibilityShowButtonName: showButtonName
                                                    })
                                                }} >
                                                    Сохранить
                                                </BlueButton>
                                            </div>

                                        </div>
                                    }
                                </TabItem>
                            )
                        }

                ].filter(x => (x != undefined))}
            />


        </Wrapper>
    );
}

const AudioPlaceholder = styled.div`
  width: 100%;
`;

const ExercisePlaceholder = styled.div`
  height: calc(100vh - 140px);

`;

const BufferPlaceholder = styled.div`
  height: calc(100vh - 140px);
  max-height: calc(100vh - 140px);
  overflow-y: auto;
`;

const NotFoundPlaceholder = styled.div`
  width: 100%;
  height: calc(80vh - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
`;

const UnderConstructionText = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(40vh - 10px);
  font-size: 24px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

const TextEditorPlaceholder = styled.div`

`;