import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import TestsAPI from "../../../api/TestsAPI";
import CommonHelper from "../../../helpers/CommonHelper";
import axios from "axios";
import {API_ENDPOINT} from "../../../constants/config";
import copy_q from "../../../assets/img/copy_q.svg";

import close_x from "../../../assets/img/close_e.svg";
import gear from "../../../assets/img/gear.svg"
import checkbox from "../../../assets/img/checkbox.svg"
import checkbox_checked from "../../../assets/img/checkbox_tick.svg"
import mine_svg from "../../../assets/img/mine.svg"
import NiceModal from "../../modals/NiceModal";
import TreadmillsAPI from "../../../api/TreadmillsAPI";
import TreadmillPanel from "./TreadmillPanel";
import {Code} from "react-content-loader";
import {RedButton} from "../../ira/ui/Buttons";
import groupsAPI from "../../../api/GroupsAPI";
import TeacherSettingsAPI from "../../../api/TeacherSettingsAPI";
import toast from "react-hot-toast";
import Spin from "arui-feather/spin";
import {useMappedState} from "redux-react-hook";
import UpdateTreadmillTaskSettings from "../treadmill/forms/UpdateTreadmillTaskSettings";
import ava from "../../../assets/img/online-learning-using-tablet.png"
import {getFixedUrl} from "../../exercises/tools/ExerciseCardItemView";
import treadmills_svg from "../../../components/templates/images/icons/treadmill.svg";

export default function TreadmillsListViewTool(props) {
    const {
        userId,
        canEdit = true,
        classId = undefined,
        selectedTaskId,
        ids = [],
        canCopy = false,
        groupId,
        haveSettings,
        isModule = false,
        isOnlineMode = false,
        onCopy = id => {

        },
        canDelete = false,
        onDelete = delId => {

        }
    } = props;

    const [selectedId, setSelectedId] = useState();
    const [treadmillId, setTreadmillId] = useState();
    const [treadmills, setTreadmills] = useState([]);
    const [settingsModalVisible, setSettingsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const [settingsLoading, setSettingsLoading] = useState(false);
    const [settingsInfo, setSettingsInfo] = useState({});
    const [students, setStudents] = useState([]);
    const [allSettings, setAllSettings] = useState([]);
    const [currentSetting, setCurrentSetting] = useState(undefined);
    const [canClose, setCanClose] = useState(true);

    useEffect(() => {
        groupsAPI.getGroupWithStudents(groupId).then(pld => {
            setStudents(pld.users);
        });
    }, [])

    const {
        currentUser
    } = useMappedState(useCallback(state => {
        let uId = state.users.currentUserId;
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId)
        }
    }));

    useEffect(() => {
        setLoading(true);
        TreadmillsAPI.getTreadmillsByIds(ids).then(arr => {
            arr = arr.filter(a => a !== undefined);
            let newIds = arr.reduce((mp, x) => mp.concat(x.id), []);
            let shouldDelete = ids.filter(x => newIds.indexOf(x) === -1);
            for (let index of shouldDelete) {
                onDelete(index);
            }
            setTreadmills(arr);
            setLoading(false);
        });
    }, [ids]);

    useEffect(() => {
        TeacherSettingsAPI.getAllUserSettings(currentUser.id).then(x => {
            x = x.filter(xx => xx.type.includes(selectedTaskId));
            setAllSettings(x);
        })
    }, [settingsModalVisible])

    let map = treadmills.reduce((mp, a) => mp.set(a.id, a), Map());
    map = map.filter(a => a.id !== undefined);
    let items = ids.map(x => map.get(x)).filter(a => (a != undefined));

    let selectedItem = treadmills.filter(xx => (xx.id == selectedId))[0];
    let isTeacher = currentUser === undefined || currentUser.userRole !== 'teacher' ? false : true;
    let shareMode = window.document.location.href.includes('/share/');

    async function saveSettings(data) {
        await TeacherSettingsAPI.saveUserSettings(currentUser.id, `t-${treadmillId}-${selectedTaskId}`, data);
        for (let student of students) {
            let pld = await TeacherSettingsAPI.getUserSettings(student.id, `t-${treadmillId}-${selectedTaskId}`);
            let times = pld.data == undefined || pld?.data?.timesCompleted == undefined ? 0 : pld.data.timesCompleted;
            pld = await TeacherSettingsAPI.saveUserSettings(student.id, `t-${treadmillId}-${selectedTaskId}`, {
                ...data,
                timesCompleted: times
            });
        }
    }

    if (loading == true) {
        return (
            <Code/>
        )
    }

    return (
        <Wrapper>
            {items.map((a, i) => {
                let setting = allSettings.filter(x => x?.type === `t-${a.id}-${selectedTaskId}`);
                let minesTurnedOn = setting.length === 0 || setting[0].data === undefined || setting[0]?.data?.recordMines === true;
                return (
                    <ItemRow isModule={isModule} key={`${a.id}_${i}`}>
                        <ImagesPlaceholder isModule={isModule}
                                           onClick={() => {
                                               setSelectedId(a.id)
                                           }}>

                            <AvatarImage src={getFixedUrl(a.avatar || ava)} isModule={isModule}/>

                            {isModule == false ? null :
                                <TreadmillImage src={treadmills_svg}/>
                            }

                        </ImagesPlaceholder>
                        <ItemInner>
                            <Span onClick={() => {
                                setSelectedId(a.id);
                                // let testUrl = `/tests/${a.id}`;
                                // if (userId != undefined) {
                                //     testUrl = `/tests/${a.id}/${userId}`;
                                // }
                                // CommonHelper.linkTo(testUrl, true);
                            }}>
                                {a.name}
                            </Span>

                            {isTeacher === false || a.items === undefined || a?.items?.filter(x => x?.hasMine === true).length === 0 ? null :
                                <div>
                                    <MineImg title={minesTurnedOn ? "В тренажёре присутствуют мины, включены" : "В тренажёре присутствуют мины, отключены"}
                                             style={{opacity: minesTurnedOn ? 1 : 0.3}}
                                             src={mine_svg}/>
                                </div>
                            }

                            {canCopy == false ? null :
                                <div>
                                    <CopyImg src={copy_q} onClick={() => {
                                        onCopy(a.id);
                                    }}/>
                                </div>
                            }

                            {haveSettings == false || isTeacher == false ? null :
                                <div>
                                    <CopyImg src={gear} onClick={async () => {
                                        setSettingsModalVisible(true);
                                        setSettingsLoading(true);
                                        let s = allSettings.filter(x => x?.type === `t-${a.id}-${selectedTaskId}`);
                                        setCurrentSetting(s.length === 0 || s[0].data === undefined ? undefined : s[0]?.data);

                                        setTreadmillId(a.id)
                                        setSettingsLoading(false);
                                    }}/>
                                </div>
                            }


                            {canDelete == false || canEdit == false ? null :
                                <div>
                                    <CopyImg src={close_x} onClick={() => {
                                        onDelete(a.id);
                                    }}/>
                                </div>
                            }
                        </ItemInner>
                    </ItemRow>
                )
            })}

            {selectedItem == undefined ? null :
                <NiceModal onClose={() => {
                    if (canClose === true) {
                        setSelectedId(undefined);
                    } else {
                        toast.error('В данный момент нельзя закрыть тредмил');
                    }
                }}>

                    <InnerModalPlaceholder>
                        <TreadmillPanel
                            selectedTaskId={selectedTaskId}
                            id={selectedId}
                            shareMode={shareMode}
                            isOnlineMode={isOnlineMode}
                            userId={userId}
                            setCanClose={(state) => {
                                setCanClose(state);
                            }}
                        />
                    </InnerModalPlaceholder>

                </NiceModal>
            }

            {settingsModalVisible === false ? null :
                <NiceModal onClose={() => {
                    setSettingsModalVisible(false);
                }}>
                    {settingsLoading === true ?
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '400px', height: '300px'}}>
                            <Spin visible={settingsLoading}/>
                        </div>
                        :
                        <SettingsInnerPlaceholder>
                            <UpdateTreadmillTaskSettings
                                settings={currentSetting}
                                onSave={async data => {
                                    setSettingsLoading(true);
                                    await saveSettings(data);
                                    setSettingsLoading(false);
                                    toast('Сохранено');
                                }}
                                onClose={() => setSettingsModalVisible(false)}
                            />

                        </SettingsInnerPlaceholder>
                    }
                </NiceModal>
            }

        </Wrapper>
    );
}

const InnerModalPlaceholder = styled.div`
  width: 720px;
  height: min(100vh - 120px, 520px);
  background: whitesmoke;
  
  @media (max-width: 720px){
    width: 100%;
    align-items: center;
  }
`;

const CopyImg = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
  opacity: 0.3;
  margin-left: 5px;

  :hover {
    opacity: 1;
  }
`;

const MineImg = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 5px;
`;

const Wrapper = styled.div`
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const Span = styled.span`
  border-bottom: 1px solid lightgrey;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const ItemRow = styled.div`
  //width: 200px;
  width: ${props => props.isModule ? '100%' : '200px'};
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.isModule ? 'flex-start' : 'center'};
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 5px;
`;

const ItemInner = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  //justify-content: space-between;
`;

const SettingsInnerPlaceholder = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  user-select: none;
`;

const ImagesPlaceholder = styled.div`
  display: flex;
  width: 100%;
  height: 140px;
  cursor: pointer;

  :hover {
    opacity: 0.9;
  }
`;

const AvatarImage = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  background-color: white;
  height: 135px;
  width: 100%;
  margin-bottom: 5px;
  cursor: pointer;
`;

const TreadmillImage = styled.div`
  height: 140px;
  width: 140px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
`;