import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import LogoDiv from "../templates/LogoDiv";
import {useMappedState} from "redux-react-hook";
import SlidesAPI from "../../api/SlidesAPI";
import ViewSlideTool from "../slides/tools/ViewSlideTool";
import LottieLoader from "../loaders/LottieLoader";

import lottie_json from '../../assets/lottie/freelancer-working-on-laptop.json'
import {translate} from "../../utils/Translate";
import ExercisePanel from "../exercises/panels/ExercisePanel";
import DialogExercisePanel from "../dialogs/panels/DialogExercisePanel";
import QuestionnairePanel from "../questionnaires/panels/QuestionnairePanel";
import ContainersAPI from "../../api/ContainersAPI";
import ModulesListPanel from "../containers/panels/ModulesListPanel";
import CommonHelper from "../../helpers/CommonHelper";
import DrochkaExercisePanel from "../drochka_exercise/panels/DrochkaExercisePanel";
import {TabItem} from "../ui/Tabs";
import TreadmillPanel from "../treadmills_exercises/panels/TreadmillPanel";
import TestPanel from "../tests/panels/TestPanel";
import UsersAPI from "../../api/UsersAPI";

export function getSortedSlides(slides = []) {
    return slides.sort((a, b) => {
        let s1 = `${a.name}`.toLowerCase();
        let s2 = `${b.name}`.toLowerCase();
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    })
}

export default function ShareApp(props) {
    let {type, id} = props.match.params;

    const [slide, setSlide] = useState();
    const [loading, setLoading] = useState(false);
    const [folder, setFolder] = useState(undefined);
    const [slides, setSlides] = useState([]);
    const [cUser, setCUser] = useState(undefined);

    const {
        currentUser
    } = useMappedState(useCallback(state => {
        let uId = state.users.currentUserId;
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId)
        }
    }));

    let sortedSlides = getSortedSlides(slides);

    useEffect(() => {
        if (type == 'modules') {
            setLoading(true);
            SlidesAPI.getSlide(id).then(d => {
                setSlide(d);
                UsersAPI.getUser(d.userId).then(u => {
                    setCUser(u);
                });
                setTimeout(() => {
                    setLoading(false);
                }, 1800)
            })
        }
        if (type == 'folder') {
            setLoading(true);
            ContainersAPI.getContainer(id).then(pld => {
                setFolder(pld);
                SlidesAPI.getContainerSlides(id).then(arr => {
                    console.log('getContainerSlides: arr = ', arr);
                    setSlides(arr);
                    setLoading(false);
                });
            });
        }
    }, [id, type]);

    // console.log('folder = ', folder);

    return (
        <Wrapper>

            {loading == false ? null :
                <LoaderPlaceholder>
                    <LottieLoader
                        json={lottie_json}
                    />
                    <LoaderTextPlaceholder>
                        {translate('loading')}
                    </LoaderTextPlaceholder>
                </LoaderPlaceholder>
            }


            {type != 'modules' || loading == true ? null :
                <SlidePanelWrapper>
                    <ViewSlideTool
                        canCopyToBuffer={false}
                        canEdit={false}
                        {...slide}
                    />

                </SlidePanelWrapper>
            }

            {'exercises'.indexOf(type) == -1 || loading == true ? null :
                <SlidePanelWrapper>
                    <ExercisePanel
                        id={id}
                    />
                </SlidePanelWrapper>
            }

            {'cards'.indexOf(type) == -1 || loading == true ? null :
                <SlidePanelWrapper>
                    <DrochkaExercisePanel
                        id={id}
                    />
                </SlidePanelWrapper>
            }

            {'treadmills'.indexOf(type) == -1 || loading == true ? null :
                <SlidePanelWrapper>
                    <div style={{height: 500, width: '100%'}}>
                        <TreadmillPanel
                            shareMode={true}
                            id={id}
                        />
                    </div>
                </SlidePanelWrapper>
            }


            {type != 'dialogs' || loading == true ? null :
                <SlidePanelWrapper>
                    <DialogExercisePanel
                        id={id}
                    />
                </SlidePanelWrapper>
            }

            {type != 'questionnaires' || loading == true ? null :
                <SlidePanelWrapper>
                    <QuestionnairePanel
                        id={id}
                    />
                </SlidePanelWrapper>
            }

            {type != 'tests' || loading == true ? null :
                <SlidePanelWrapper>
                    <TestPanel
                        id={id}
                    />
                </SlidePanelWrapper>
            }


            {type != 'folder' || loading == true || folder == undefined ? null :
                <SlidePanelWrapper>
                    <FolderNamePlaceholder>
                        {folder?.name}
                    </FolderNamePlaceholder>

                    {sortedSlides.map((a, i) => {
                        return (
                            <SlideItem key={a.id} onClick={() => {
                                CommonHelper.linkTo(`/share/folder/${id}/unit/${a?.id}`)
                            }}>
                                {a?.name}
                            </SlideItem>
                        )
                    })}

                </SlidePanelWrapper>
            }

        </Wrapper>
    );
}

const SlideItem = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
`;

const FolderNamePlaceholder = styled.div`
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid whitesmoke;
  padding-bottom: 10px;
`;

const LoaderTextPlaceholder = styled.div`
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoaderPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`

`;

const SlidePanelWrapper = styled.div`
  width: 720px;
  margin: 0 auto;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-top: 20px;

  //border: 1px solid lightgrey;
  padding: 20px;
  border-radius: 4px;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width: 720px) {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  box-sizing: border-box;
  width: 1120px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  background: white;
  padding: 10px;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;


const NamePlaceholder = styled.div`
  margin-left: 20px;
  box-sizing: border-box;
  font-weight: bold;
`;

const TopPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  background: white;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopLeftPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopRightPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  font-weight: bold;
`;