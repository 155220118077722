import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {DEFAULT_VIDEO_PLACEHOLDER} from "../../../constants/config";
import ReactPlayer from "react-player";
import TranslatableParagraph from "../../translate/panels/TranslatableParagraph";
import MosesPlayer from "../../moses/MosesPlayer";
import WrappedTranslatableParagraph from "../../translate/panels/WrappedTranslatableParagraph";

let isEmpty = a => (a == undefined || a == '');

export const getFixedUrl = xUrl => {
    console.log('getFixedUrl: xUrl = ', xUrl);
    if (xUrl == undefined) {
        return undefined;
    }
    let s = `${xUrl}`.replace('https://www.englishpatientdrive.pw/dropzone/uploads/', 'https://files.entsu.ru/files/');
    s = `${s}`.replace('http://disk.englishpatient.org/uploads/', 'https://files.entsu.ru/files/');
    s = `${s}`.replace('https://www.englishpatient.org/old-disk-uploads/', 'https://files.entsu.ru/files/');
    s = `${s}`.replace('https://files.ru-spb-2.englishpatient.org/', 'https://files.entsu.ru/');
    s = `${s}`.replace('https://drive.sabir.pro/images/', 'https://files.entsu.ru/files/images/images/');
    return s;
}

function isHebrew(text) {
    if (text === undefined || text.trim() === '') {
        return false;
    }
    let hebrewChars = /[\u0590-\u05FF]+/g;
    let s = text.replace(hebrewChars, '')
    return s.length < text.length
}

export default function ExerciseCardItemView(props) {
    const {
        id,
        videoUrl,
        imageUrl,
        text,
        audioUrl,

        videoAvatar,
        mosesDurations = [],

        hint = '',
        comment = '',

        answerType,
        html = ''

    } = props;

    let hasVideo = !isEmpty(videoUrl);
    let hasImage = !isEmpty(imageUrl);
    let hasAudio = !isEmpty(audioUrl);
    let hasText = !isEmpty(text) && (`${answerType}`.indexOf('dnd_') == -1) && (answerType != 'emphasis') && (answerType != 'input_gaps');
    let hasHtml = !isEmpty(html);

    const [hintVisible, setHintVisible] = useState(false);
    const [commentVisible, setCommentVisible] = useState(false);

    useEffect(() => {
        setHintVisible(false);
        setCommentVisible(false);
    }, [id]);

    console.log('ExerciseCardItemView: render');

    return (
        <Wrapper>

            {hasVideo == false ? null :
                <VideoPlaceholder key={videoUrl}>
                    {(mosesDurations == undefined || mosesDurations.length == 0) ?
                        <ReactPlayer
                            width={'100%'}
                            height={'100%'}
                            playing={true}
                            light={getFixedUrl(videoAvatar) || DEFAULT_VIDEO_PLACEHOLDER}
                            controls={true}
                            url={getFixedUrl(videoUrl)}/>
                        :
                        <MosesPlayer
                            key={`c${videoUrl}`}
                            avatar={getFixedUrl(videoAvatar) || DEFAULT_VIDEO_PLACEHOLDER}
                            url={getFixedUrl(videoUrl)}
                            fragments={mosesDurations}
                        />
                    }
                </VideoPlaceholder>
            }

            {hasImage == false ? null :
                <ImagePlaceholder>
                    {/*<Image*/}
                    {/*    src={imageUrl}*/}
                    {/*/>*/}
                    <OptImg
                        src={getFixedUrl(imageUrl)}
                    />
                </ImagePlaceholder>
            }

            {hasAudio == false ? null :
                <AudioPlaceholder>
                    <audio controls={true}
                           src={getFixedUrl(audioUrl)}
                    ></audio>
                </AudioPlaceholder>
            }

            {hasText == false ? null :
                <TextPlaceholder>
                    {answerType == 'highlight' ? null :
                        isHebrew(text) ?
                            <p style={{textAlign: 'right'}}><span dir="rtl">{text}</span></p>
                            :
                            <WrappedTranslatableParagraph
                                text={text}
                            />
                    }

                </TextPlaceholder>
            }

            {hasHtml == false ? null :
                <TextPlaceholder>
                    <div dangerouslySetInnerHTML={{__html: html}}></div>
                </TextPlaceholder>
            }


            {comment == undefined || comment == '' ? null :
                <HintPlaceholder>
                    <TopHintPlaceholder>
                        <HintSpan onClick={() => {
                            setCommentVisible(!commentVisible);
                        }}>
                            Комментарий
                        </HintSpan>
                    </TopHintPlaceholder>
                    {commentVisible == false ? null :
                        <HintContentPlaceholder
                            style={{textAlign: isHebrew(comment) ? 'right' : 'left'}}
                            dir={isHebrew(comment) ? 'rtl' : 'ltr'}>
                            {comment}
                        </HintContentPlaceholder>
                    }
                </HintPlaceholder>
            }

            {hint == undefined || hint == '' ? null :
                <HintPlaceholder>
                    <TopHintPlaceholder>
                        <HintSpan onClick={() => {
                            setHintVisible(!hintVisible);
                        }}>
                            Подсказка
                        </HintSpan>
                    </TopHintPlaceholder>
                    {hintVisible == false ? null :
                        <HintContentPlaceholder
                            style={{textAlign: isHebrew(hint) ? 'right' : 'left'}}
                            dir={isHebrew(hint) ? 'rtl' : 'ltr'}>
                            {hint}
                        </HintContentPlaceholder>
                    }
                </HintPlaceholder>
            }


        </Wrapper>
    );
}

const HintPlaceholder = styled.div`
  font-style: italic;
  margin-bottom: 5px;
`;

const HintContentPlaceholder = styled.div`
  padding: 5px;
  background: whitesmoke;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  opacity: 0.7;
`;

const TopHintPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
`;

const HintSpan = styled.span`
  opacity: 0.4;
  font-size: 12px;
  font-style: italic;
  cursor: pointer;
  border-bottom: 1px dashed lightgrey;

  :hover {
    opacity: 0.7;
  }
`;

const Wrapper = styled.div`
  border-bottom: 1px solid whitesmoke;
`;

const TextPlaceholder = styled.div`
  max-height: 160px;
  overflow-y: auto;
  padding: 10px;
  font-size: 14px;
  font-weight: normal;
  box-sizing: border-box;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 1px solid whitesmoke;
`;

const AudioPlaceholder = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const ImagePlaceholder = styled.div`
  width: 100%;
`;

const Image = styled.img`
  width: 100%;
`;

const OptImg = styled.div`
  background-color: white;
  width: 100%;
  height: 420px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
`;

const VideoPlaceholder = styled.div`
  width: 100%;
  height: 380px;
  background: whitesmoke;
`;