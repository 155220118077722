import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import loupe from '../../../assets/img/loupe.png'
import waiting from '../../../assets/img/waiting.png'
import YandexTranslatePanel from "../../translate/panels/YandexTranslatePanel";

export default function AddNewVocabularyWordPanel(props) {
    const {
        placeholder = 'Поиск...',
        userId,
        onAdded = () => {

        }
    } = props;

    const [searchQuery, setSearchQuery] = useState('');
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);


    return (
        <Wrapper>

            <Field>
                <Input onChange={evt => {
                    let txt = evt.target.value;
                    setText(txt);
                    setSearchQuery(undefined);
                }}
                       autoFocus={true}
                       value={text}
                       placeholder={placeholder}
                       onKeyPress={event => {
                           let txt = event.target.value;
                           if (event.key == 'Enter') {
                               if (txt == undefined || txt.trim() == '') {
                                   return;
                               }
                               setSearchQuery(txt);
                           }
                       }}
                />

                <SearchImage
                    src={(loading == true) ? waiting : loupe}
                    onClick={() => {
                        this.search();
                    }}
                />

            </Field>

            {(searchQuery == undefined || searchQuery == '') ? null :
                <SearchResultsPanel key={searchQuery}>
                    <YandexTranslatePanel {...props}
                                          word={searchQuery}
                                          plusColor={'black'}
                                          onAdded={() => {
                                              onAdded();
                                          }}
                    />
                </SearchResultsPanel>
            }

        </Wrapper>
    );
}


const Wrapper = styled.div`

`;

const SearchResultsPanel = styled.div`

`;

const Input = styled.input`
  box-sizing: border-box;
  height: 36px;
  line-height: 36px;
  border: none;
  border-bottom: 1px solid whitesmoke;
  outline: none;
  width: 100%;
  font-size: 18px;

  :focus {
    border-color: blue;
  }
`;

const Field = styled.div`
  position: relative;
  
  @media (max-width: 720px){
    width: 90%;
  }
`;

const SearchImage = styled.img`
  position: absolute;
  right: 5px;
  top: 5px;
  width: 18px;
  height: 18px;
  opacity: 0.5;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;
