import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import EmphasisEditor from "../emphasis/tools/EmphasisEditor";
import DiffHelper from "../../helpers/DiffHelper";

export default function DevApp(props) {
    const [t, setT] = useState(+new Date());

    const [positions, setPositions] = useState([]);

    const [text1, setText1] = useState('');
    const [text2, setText2] = useState('');

    const score = DiffHelper.getScore(text1, text2);

    return (
        <Wrapper>

            <input value={text1} onChange={evt => {
                setText1(evt.target.value);
            }}/>

            <input value={text2} onChange={evt => {
                setText2(evt.target.value);
            }}/>

            <pre dangerouslySetInnerHTML={{__html: JSON.stringify(score, null, 2)}}>

            </pre>

            {/*<EmphasisEditor*/}
            {/*    text={'англо-русском словаре, что неизбежно приводит к изучению англо-русского языка.'}*/}
            {/*    positions={positions}*/}
            {/*    onChange={x => {*/}
            {/*        console.log('onChange: x = ', x);*/}
            {/*        setPositions(x);*/}
            {/*    }}*/}
            {/*/>*/}


        </Wrapper>
    );
}

const Wrapper = styled.div`

`;